import React from "react";

export default function Play(props) {
  const { handleClick } = props;
  
  return (
    <button className="player__button" onClickCapture={() => handleClick()}>
     <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
      <defs>
        <filter id="Ellipse_2" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="3" result="blur"/>
          <feFlood flood-opacity="0.161"/>
          <feComposite operator="in" in2="blur"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <g id="Pause" transform="translate(-219 -256)">
        <g transform="matrix(1, 0, 0, 1, 219, 256)" filter="url(#Ellipse_2)">
          <circle id="Ellipse_2-2" data-name="Ellipse 2" cx="13" cy="13" r="13" transform="translate(9 6)"/>
        </g>
        <g id="Group_94" data-name="Group 94" transform="translate(25.64 -1.17)">
          <line id="Line_37" data-name="Line 37" y2="10" transform="translate(213.36 271.17)" fill="none" stroke="#fffb00" stroke-width="2"/>
          <line id="Line_38" data-name="Line 38" y2="10" transform="translate(217.36 271.17)" fill="none" stroke="#fffb00" stroke-width="2"/>
        </g>
      </g>
    </svg>

    </button>
  );
}
