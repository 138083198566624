import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { useState, useRef } from "react";
import { useContext } from "react";
import TermsChecked from "../context/TermsChecked";
import IsTermsPopUp from "../context/IsTermsPopUp";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";

export const TermsPopUp = ({ type }) => {
  const [isTermsChecked, setIsTermsChecked] = useContext(TermsChecked);
  const [isPopupOpen, setIsPopupOpen] = useContext(IsTermsPopUp);
  const [bottom, setBottom] = useState(false);
  const handleScroll = (e) => {
    const bottomCheck = e.target.scrollTop + 450 > e.target.scrollHeight;
    // e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    // console.log(bottomCheck)
    // console.log(e.target.scrollTop)
    // console.log(e.target.scrollHeight)
    if (bottomCheck) {
      setBottom(true);
      console.log(bottomCheck);
    }
  };
  const scrollToBottom = () => {
    document.getElementById("scrollable-div").scrollTo({
      // top: 500000,
      top: document.getElementById("scrollable-div").scrollHeight,
      left: 0,
      behavior: "smooth",
    });
    // el.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    // document.querySelector("#scrollable-div").scrollIntoView()
    // document.getElementById("scrollable-div").scrollTo(0, 5000);
    // document.getElementById("scrollable-div").scrollTo(0,500)
    console.log("down");
  };
  return (
    <Dialog
      open={isPopupOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {"TERMS AND CONDITIONS"}
          {type === "signin" ? (
            <CloseIcon
              style={{ fontSize: "32px" }}
              onClick={() => {
                setIsPopupOpen(false);
              }}
            />
          ) : (
            <ArrowBackIcon
              style={{
                transform: "rotate(-90deg)",
                color: "#fffb00",
                background: "#000000",
                padding: "8px",
              }}
              onClick={() => {
                scrollToBottom();
              }}
            />
          )}
        </div>
      </DialogTitle>
      <DialogContent
        id="scrollable-div"
        onScroll={(e) => {
          handleScroll(e);
        }}
        style={{ overflowY: "scroll", maxHeight: "400px" }}
      >
        <DialogContentText
          id="alert-dialog-description"
          style={{ color: "#000" }}
        >
          {/* Oops! looks like you’ve skipped the previous Game QR Code, Please
            scan the Game QR Code to continue.! */}
          <div>
            <>
              <p
                style={{
                  marginTop: "6.0pt",
                  marginRight: "0cm",
                  marginBottom: "12.0pt",
                  marginLeft: "0cm",
                  textAlign: "center",
                  fontSize: 21,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Escapely®&nbsp;Adventure Box, Escapely® Adventure Box Team
                Building, Escapely® Team Building, &amp; Escapely® Scavenger
                Quest Terms and Conditions
              </p>
              <p
                style={{
                  marginTop: "6.0pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 16,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  textAlign: "justify",
                }}
              >
                This Escapely® Adventure, Escapely® Adventure Box Team Building,
                Escapely® Team Building, &amp; Escapely® Scavenger Quest Box
                Terms and Conditions (“
                <strong>Agreement</strong>”) is a binding agreement between you
                (“
                <strong>End User</strong>” or “<strong>you</strong>”) and
                Escapely Virtual LLC; Escapely Live LLC; Escapely Games LLC; and
                Escapely LLC (collectively, “<strong>Escapely</strong>,” “
                <strong>we</strong>,” “<strong>us</strong>,” or “
                <strong>our</strong>”). This Agreement governs your use of the
                Escapely® Adventure Box, Escapely® Adventure Box Team Building,
                Escapely® Team Building, &amp; Escapely® Scavenger Quest
                products and its associated web based application (including all
                related content and documentation, the “
                <strong>Application</strong>”). The Application is licensed, not
                sold, to you.
              </p>
              <p
                style={{
                  marginTop: "6.0pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 16,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  textAlign: "justify",
                }}
              >
                BY CLICKING THE “ACCEPT” BUTTON, YOU (A) ACKNOWLEDGE THAT YOU
                HAVE READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU
                ARE 18 YEARS OF AGE OR OLDER, OR THE AGE OF MAJORITY IN YOUR
                JURISDICTION; AND (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU
                ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THESE
                TERMS, DO NOT USE THE APPLICATION.
              </p>
              <p
                style={{
                  marginTop: "6.0pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 16,
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  textAlign: "justify",
                }}
              >
                <span style={{ fontFamily: '"Times New Roman",serif' }}>
                  By accessing or using the Application, you represent and
                  warrant that you have not been previously suspended or removed
                  from the Application, or our Website, or engaged in any
                  activity that could result in suspension or removal from the
                  Application or our Website.
                </span>
              </p>
              <p
                style={{
                  marginTop: "6.0pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 16,
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                  textAlign: "justify",
                }}
              >
                <span style={{ fontFamily: '"Times New Roman",serif' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: "6.0pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 16,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  textAlign: "justify",
                }}
              >
                <strong>
                  <u>
                    <span style={{ fontSize: 19 }}>
                      Note that Section 18 of this Agreement contains a
                      mandatory arbitration provision that requires the use of
                      arbitration on an individual basis and limits the remedies
                      available to you in the event of certain disputes.
                    </span>
                  </u>
                </strong>
              </p>
              <p
                style={{
                  marginTop: "6.0pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 16,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  textAlign: "justify",
                }}
              >
                <strong>
                  <u>
                    <span style={{ fontSize: 19 }}>
                      <span style={{ textDecoration: "none" }}>&nbsp;</span>
                    </span>
                  </u>
                </strong>
              </p>
              <p
                style={{
                  marginTop: "6.0pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 16,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  textAlign: "justify",
                }}
              >
                <strong>SAFETY NOTICE</strong>: You acknowledge that the
                Application may involve augmented reality or aspects that
                require you to travel and visit a physical location. You agree
                to pay special attention to your surroundings while using the
                Application. You agree not to trespass on private property (even
                if it appears necessary to get access to the next clue, i.e.,
                just because the clue might be located on private property does
                not give you the permission to trespass), cross streets
                illegally, or cause an act or omission that would increase any
                risk of harm to you or others while using the Application. You
                must pay attention to your surroundings to include time of day
                of use and any potential hazards that may exist in the area
                while using the Application. By accepting this Agreement, you
                agree to comply with all applicable federal, state and local
                statutes, laws, ordinances, rules and regulations, pertaining to
                the Agreement and the performance of your responsibilities and
                obligations in this Agreement and relating to your use of the
                Application. Escapely specifically disclaims any harm caused by
                your act or omission while using the Application.
              </p>
              <p
                style={{
                  marginTop: "6.0pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 16,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  textAlign: "justify",
                }}
              >
                You agree that in conjunction with your use of the Application,
                you will maintain safe and appropriate contact with other
                players and other people in the real world. You will not harass
                threaten or otherwise violate the legal rights of others. You
                will not trespass, or in any manner attempt to gain or gain
                access to any property or location where you do not have a right
                or permission to be, and will not otherwise engage in any
                activity that may result in injury, death, property damage,
                nuisance, or liability of any kind. If asked to leave any
                premises, you agree to do so. If you have a dispute with any
                third party relating to your use of the Application, you release
                Escapely (and our officers, directors, agents, subsidiaries,
                joint ventures, and employees) from all claims, demands, and
                damages (actual and consequential) of every kind and nature,
                known and unknown, suspected and unsuspected, disclosed and
                undisclosed, arising out of or in any way connected with such
                disputes and your use of the Application.
              </p>
              <ol
                style={{ listStyleType: "decimal", marginLeft: "0cmundefined" }}
              >
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16, background: "white" }}>
                      License Grant
                    </span>
                  </span>
                  . Subject to the terms of this Agreement, Escapely grants you
                  a limited, non-exclusive, and nontransferable license to:
                  <ol style={{ listStyleType: "undefined" }}>
                    <li>
                      &nbsp;use the Application for your personal,
                      non-commercial use on a single device owned or otherwise
                      controlled by you (“
                      <strong>Device</strong>”) strictly in accordance with the
                      Application’s documentation and this Agreement; and
                    </li>
                    <li>
                      access, stream, download, and use on such Device the
                      Content and Services (as defined in Section&nbsp;6) made
                      available in or otherwise accessible through the
                      Application or Website, strictly in accordance with this
                      Agreement and the Website terms and conditions located at
                      https://escapely.com/terms-and-conditions/ (“
                      <strong>Terms of Use</strong>”) applicable to such Content
                      and Services as set forth in&nbsp;Section&nbsp;6.
                    </li>
                  </ol>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16, background: "white" }}>
                      License Restrictions
                    </span>
                  </span>
                  . You shall not:
                  <ol style={{ listStyleType: "undefined" }}>
                    <li>
                      copy the Application, except as expressly permitted by
                      this license;
                    </li>
                    <li>
                      modify, translate, adapt, or otherwise create derivative
                      works or improvements, whether or not patentable, of the
                      Application;
                    </li>
                    <li>
                      reverse engineer, disassemble, decompile, decode, or
                      otherwise attempt to derive or gain access to the source
                      code of the Application or any part thereof;
                    </li>
                    <li>
                      remove, delete, alter, or obscure any trademarks or any
                      copyright, patent, or other intellectual property or
                      proprietary rights notices from the Application, including
                      any copy thereof;
                    </li>
                    <li>
                      rent, lease, lend, sell, sublicense, assign, distribute,
                      publish, transfer, or otherwise make available the
                      Application, or any features or functionality of the
                      Application, to any third party for any reason, including
                      by making the Application available on a network where it
                      is capable of being accessed by more than one device at
                      any time; or
                    </li>
                    <li>
                      remove, disable, circumvent, or otherwise create or
                      implement any workaround to any copy protection, rights
                      management, or security features in or protecting the
                      Application.
                    </li>
                  </ol>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16, background: "white" }}>
                      Reservation of Rights
                    </span>
                  </span>
                  . You acknowledge and agree that the Application is provided
                  under a limited and revocable license, and not sold, to you.
                  You do not acquire any ownership interest in the Application
                  under this Agreement, or any other rights thereto other than
                  to use the Application in accordance with the license granted,
                  and subject to all terms, conditions, and restrictions, under
                  this Agreement. Escapely and its licensors and service
                  providers reserve and shall retain their entire right, title,
                  and interest in and to the Application, including all
                  copyrights, trademarks, and other intellectual property rights
                  therein or relating thereto, except as expressly granted to
                  you in this Agreement. ESCAPELY® and any other Escapely
                  product or service names, trademarks, logos, or other indicia
                  (“
                  <strong>Marks</strong>”) that may appear on the Application
                  are the property of Escapely or its subsidiaries, affiliates
                  or third parties, and may not be copied, imitated or used, in
                  whole or in part, without our prior written permission.
                  Nothing contained in this Agreement shall be construed as
                  granting, by implication or otherwise, any license or right to
                  use any such Marks without the prior written permission of
                  Escapely or such third party that may own such Marks. Your
                  misuse of any such Marks, is strictly prohibited.
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      Registration and Personal Information. &nbsp; In order to
                      access and use certain areas or features of the Website or
                      Application, you may need to provide certain information
                      and/or answer certain questions through account
                      registration. Each registration is for a single user only.
                      You agree to (a) provide accurate, current and complete
                      information, (b) maintain and promptly update, as
                      necessary, your information, (c) be responsible for the
                      acts or omissions of any third party who has authority to
                      access or use the Website or Application on your behalf,
                      or through your account, and for the acts or omissions of
                      those who are playing the associated Website or
                      Application games with you, and (d) immediately notify us
                      if you discover or otherwise suspect any security breaches
                      related to the Website or Application. If you provide
                      information that is untrue, inaccurate, not current or
                      incomplete, we may suspend and/or terminate any and all
                      current or future use of the Website and/or the
                      Application. By providing information and/or answering
                      questions, or registering an account, you also consent to
                      receive electronic communications from Escapely (e.g., via
                      email or by posting notices to the Website or
                      Application). These communications may include notices and
                      are part of your relationship with us. You agree that any
                      notices, agreements, disclosures or other communications
                      that we send to you electronically will satisfy any legal
                      communication requirements, including, but not limited to,
                      that such communications be in writing. You should
                      maintain copies of electronic communications from us by
                      printing a paper copy or saving an electronic copy. We may
                      also send you promotional communications via email,
                      including, but not limited to, newsletters, special
                      offers, surveys and other news and information we think
                      will be of interest to you. You may opt out of receiving
                      these promotional emails at any time by following the
                      unsubscribe instructions provided therein.
                    </span>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16, background: "white" }}>
                      Collection and Use of Your Information
                    </span>
                  </span>
                  . You acknowledge that when you use the Application, Escapely
                  may use automatic means (including, for example, cookies and
                  web beacons) to collect information about your Device and
                  about your use of the Application. You also may be required to
                  provide certain information about yourself as a condition to
                  downloading, installing, or using the Application or certain
                  of its features or functionality, and the Application may
                  provide you with opportunities to share information about
                  yourself with others. All information we collect through or in
                  connection with this Application is subject to our Privacy
                  Policy at https://escapely.com/privacy-policy/ (“
                  <strong>Privacy Policy</strong>”). By downloading, installing,
                  using, and providing information to or through this
                  Application, you consent to all actions taken by us with
                  respect to your information in compliance with the Privacy
                  Policy.
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16, background: "white" }}>
                      Content and Services
                    </span>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>; Registration</span>
                  </span>
                  . The Application may provide you with access to Escapely’s
                  website located at https://escapely.com/ (the “
                  <strong>Website</strong>”) and products and services
                  accessible thereon, and certain features, functionality, and
                  content accessible on or through the Application
                  (collectively, “<strong>Content and Services</strong>”). In
                  addition to this Agreement, your access to and use of such
                  Content and Services are governed by the Website’s Terms of
                  Use, and Privacy Policy located at
                  https://escapely.com/terms-and-conditions/ and
                  https://escapely.com/privacy-policy/, which are incorporated
                  herein by this reference, and this Agreement. Your access to
                  and use of such Content and Services, and access to the
                  Application, may require you to acknowledge your acceptance of
                  such Terms of Use and Privacy Policy and/or to register with
                  the Website, and your failure to do so may restrict you from
                  accessing or using certain of the Application’s features and
                  functionality. Any violation of such Terms of Use will also be
                  deemed a violation of this Agreement.
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16, background: "white" }}>
                      Geographic Restrictions
                    </span>
                  </span>
                  . The Content and Services are based in the state of Nevada in
                  the United States and provided for access and use only by
                  persons located in the United States. You acknowledge that you
                  may not be able to access all or some of the Content and
                  Services outside of the United States and that access thereto
                  may not be legal by certain persons or in certain countries.
                  If you access the Content and Services from outside the United
                  States, you are responsible for compliance with local laws.
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16, background: "white" }}>
                      Updates
                    </span>
                  </span>
                  . Escapely may from time to time in its sole discretion
                  develop and provide Application updates, which may include
                  upgrades, bug fixes, patches, other error corrections, and/or
                  new features (collectively, including related documentation, “
                  <strong>Updates</strong>”). Updates may also modify or delete
                  in their entirety certain features and functionality. You
                  agree that Escapely has no obligation to provide any Updates
                  or to continue to provide or enable any particular features or
                  functionality. Based on your Device settings when your Device
                  is connected to the internet either:
                  <ol style={{ listStyleType: "undefined" }}>
                    <li>
                      the Application will automatically download and install
                      all available Updates; or
                    </li>
                    <li>
                      you may receive notice of or be prompted to download and
                      install available Updates.
                    </li>
                  </ol>
                </li>
              </ol>
              <p
                style={{
                  marginTop: "12.0pt",
                  marginRight: "0cm",
                  marginBottom: "12.0pt",
                  marginLeft: "0cm",
                  textIndent: "72.0pt",
                  fontSize: 13,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  textAlign: "justify",
                }}
              >
                You shall promptly download and install all Updates once made
                available and acknowledge and agree that the Application or
                portions thereof may not properly operate should you fail to do
                so. You further agree that all Updates will be deemed part of
                the Application and be subject to all terms and conditions of
                this Agreement.
              </p>
              <ol
                style={{
                  listStyleType: "undefined",
                  marginLeft: "0cmundefined",
                }}
              >
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16, background: "white" }}>
                      Third-Party Materials
                    </span>
                  </span>
                  . The Application may display, include, or make available
                  third-party content (including data, information,
                  applications, and other products, services, and/or materials)
                  or provide links to third-party websites or services,
                  including through third-party advertising (“
                  <strong>Third-Party Materials</strong>”). You acknowledge and
                  agree that Escapely is not responsible for Third-Party
                  Materials, including their accuracy, completeness, timeliness,
                  validity, copyright compliance, legality, decency, quality, or
                  any other aspect thereof. Escapely does not assume and will
                  not have any liability or responsibility to you or any other
                  person or entity for any Third-Party Materials. Third-Party
                  Materials and links thereto are provided solely as a
                  convenience to you, and you access and use them entirely at
                  your own risk and subject to such third parties’ terms and
                  conditions.
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      Merchant Purchase Services. &nbsp;To the extent any
                      purchase you make through the Website or Application
                      involves third party merchant services for which Escapely
                      may enable access for your use in conjunction with the
                      Application (“
                    </span>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span style={{ fontSize: 16 }}>Merchant Services</span>
                    </strong>
                  </span>
                  <span>
                    <span>
                      <u>
                        <span>
                          &rdquo;), such as purchases made through WooCommerce
                          <br />
                          <strong style={{ fontSize: "12px" }}>
                            (https://woocommerce.com/payments/)
                          </strong>
                          <br />
                          or BrainTree (
                        </span>
                      </u>
                    </span>
                  </span>
                  <br />
                  <strong style={{ fontSize: "12px" }}>
                    http://www.braintreepayments.com/),
                  </strong>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      ), or other third party payment vendor, you understand and
                      agree that your use of any Merchant Services will be
                      governed exclusively by such merchant’s terms and
                      conditions as detailed on their applicable website or
                      purchasing page. You understand and agree that Escapely is
                      not responsible for your, nor any third party’s,
                      obligations relating to any Merchant Services, or any
                      payments made through such Merchant Services, or any
                      applicable refunds or payment disputes relating to such
                      Merchant Services, and Escapely specifically disclaims any
                      liability relating thereto.&nbsp;
                    </span>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      Purchase Orders; Shipment; Refunds. &nbsp;
                    </span>
                  </span>
                  <ol style={{ listStyleType: "undefined" }}>
                    <li>
                      <span
                        style={{
                          fontFamily: '"Times New Roman",serif',
                          color: "black",
                          fontWeight: "normal",
                          textDecoration: "none underline",
                          verticalAlign: "baseline",
                        }}
                      >
                        <span style={{ fontSize: 16 }}>
                          Orders. &nbsp;You agree that your order is an offer to
                          buy, under this Application, all products and services
                          listed in your order. All orders must be accepted by
                          us, or we will not be obligated to sell the products
                          or services to you. We may choose not to accept orders
                          at our sole discretion, even after we send you a
                          confirmation email with your order number and details
                          of the items you have ordered.&nbsp;
                        </span>
                      </span>
                    </li>
                    <li>
                      <span
                        style={{
                          fontFamily: '"Times New Roman",serif',
                          color: "black",
                          fontWeight: "normal",
                          textDecoration: "none underline",
                          verticalAlign: "baseline",
                        }}
                      >
                        <span style={{ fontSize: 16 }}>
                          Prices and Payment Terms. &nbsp;All prices, discounts,
                          and promotions posted on our Website are subject to
                          change without notice. The price charged for a product
                          or service will be the price in effect at the time the
                          order is placed and will be set out in your order
                          confirmation. Price increases will only apply to
                          orders placed after such changes. Posted prices do not
                          include taxes or charges for shipping and handling.
                          All such taxes and charges will be added to your total
                          and will be itemized in your shopping cart and your
                          order confirmation. We strive to display accurate
                          price information, however, we may, on occasion, make
                          inadvertent typographical errors, inaccuracies, or
                          omissions related to pricing and availability. We
                          reserve the right to correct any errors, inaccuracies,
                          or omissions at any time and to cancel any orders
                          arising from such occurrences. We may offer, from time
                          to time, promotions on our Website that may affect
                          pricing and that are governed by terms and conditions
                          separate from this Agreement. If there is a conflict
                          between the terms for a promotion and this Agreement,
                          the promotion terms will govern. Terms of payment are
                          within our sole discretion and payment must be
                          received by us before we accept an order. You
                          represent and warrant that (i) the credit card
                          information you supply to us is true, correct, and
                          complete, (ii) you are duly authorized to use such
                          credit card or payment method for the purchase, (iii)
                          charges incurred by you will be honored by your credit
                          card company or banking institution, and (iv) you will
                          pay charges incurred by you at the posted prices,
                          including shipping and handling charges and all
                          applicable taxes, if any, regardless of the amount
                          quoted on our Website at the time of your order. You
                          represent and warrant that you are buying products or
                          services from our Website for your own personal or
                          household use only, and not for resale or export. You
                          further represent and warrant that all purchases are
                          intended for final delivery to locations within the
                          US.
                        </span>
                      </span>
                    </li>
                    <li>
                      <span
                        style={{
                          fontFamily: '"Times New Roman",serif',
                          color: "black",
                          fontWeight: "normal",
                          textDecoration: "none underline",
                          verticalAlign: "baseline",
                        }}
                      >
                        <span style={{ fontSize: 16 }}>
                          Price Changes; Limitations. &nbsp;Prices for our
                          products are subject to change without notice. We
                          reserve the right at any time to modify or discontinue
                          a product or service (or any part or content thereof)
                          without notice at any time. &nbsp;We shall not be
                          liable to you or to any third-party for any
                          modification, price change, suspension or
                          discontinuance of a product. Certain products or
                          services may be available exclusively online through
                          the Website or Application. We have made every effort
                          to display as accurately as possible the colors and
                          images of our products that appear at the store. We
                          cannot guarantee that your computer monitor’s display
                          of any color will be accurate. &nbsp; We reserve the
                          right, but are not obligated, to limit the sales of
                          our products or services to any person, geographic
                          region or jurisdiction for any reason or no reason at
                          any time. We may exercise this right on a case-by-case
                          basis. We reserve the right to limit the quantities of
                          any products or services that we offer. All
                          descriptions of products or product pricing are
                          subject to change at any time without notice, at the
                          sole discretion of us. Any offer for any product or
                          service made on the Website or Application is void
                          where prohibited. You may not use our products for any
                          illegal or unauthorized purpose nor may you, in the
                          use of the Website or Application, violate any laws in
                          your jurisdiction. We reserve the right to refuse any
                          order you place with us. We may, in our sole
                          discretion, limit or cancel quantities purchased per
                          person, per household or per order. These restrictions
                          may include orders placed by or under the same
                          customer account, the same credit card, and/or orders
                          that use the same billing and/or shipping address. In
                          the event that we make a change to or cancel an order,
                          we may attempt to notify you by contacting the e mail
                          and/or billing address/phone number provided at the
                          time the order was made. We reserve the right to limit
                          or prohibit orders that, in our sole judgment, appear
                          to be placed by dealers, resellers or
                          distributors.&nbsp;
                        </span>
                      </span>
                    </li>
                    <li>
                      <span
                        style={{
                          fontFamily: '"Times New Roman",serif',
                          color: "black",
                          fontWeight: "normal",
                          textDecoration: "none underline",
                          verticalAlign: "baseline",
                        }}
                      >
                        <span style={{ fontSize: 16 }}>
                          Shipment. &nbsp;We will arrange for shipment of the
                          products to you. Please check the individual product
                          page for specific delivery options. You will pay all
                          shipping and handling charges specified during the
                          ordering process, unless provided otherwise on the
                          product page. Shipping and handling charges are
                          reimbursement for the costs we incur in the
                          processing, handling, packing, shipping, and delivery
                          of your order. Title and risk of loss pass to you upon
                          our transfer of the products to the carrier. Shipping
                          and delivery dates are estimates only and cannot be
                          guaranteed. We are not liable for any delays in
                          shipments.&nbsp;
                        </span>
                      </span>
                    </li>
                    <li>
                      <span
                        style={{
                          fontFamily: '"Times New Roman",serif',
                          color: "black",
                          fontWeight: "normal",
                          textDecoration: "none underline",
                          verticalAlign: "baseline",
                        }}
                      >
                        <span style={{ fontSize: 16 }}>
                          Returns and Refunds. &nbsp;At Escapely, we strive
                          toward customer satisfaction with our products and
                          purchases. Except for any products designated on the
                          Website as final sale or non-returnable, we will
                          accept a return of the products for a refund of your
                          purchase price, less the original shipping and
                          handling costs, provided such return is made within
                          thirty (30) days of shipment with valid proof of
                          purchase and provided such products are returned in
                          their original unopened condition. You are responsible
                          for all shipping and handling charges on returned
                          items. You bear the risk of loss during shipment. We
                          therefore strongly recommend that you fully insure
                          your return shipment against loss or damage and that
                          you use a carrier that can provide you with proof of
                          delivery for your protection. Refunds are processed
                          within approximately fourteen (14) business days of
                          our receipt of your merchandise. Your refund will be
                          credited back to the same payment method used to make
                          the original purchase on the Website. WE OFFER NO
                          REFUNDS ON ANY PRODUCTS DESIGNATED ON THE WEBSITE AS
                          NON-RETURNABLE.
                        </span>
                      </span>
                    </li>
                  </ol>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>User Content.</span>
                  </span>{" "}
                  The Application and/or Website may allow you to post or
                  display certain materials and content.{" "}
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      You are solely responsible and liable for all data,
                      information, photos, images, text, content and other
                      materials (“
                    </span>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span style={{ fontSize: 16 }}>User Content</span>
                    </strong>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      ”) that you submit, upload, post, e-mail or otherwise
                      transmit (“
                    </span>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span style={{ fontSize: 16 }}>Transmit</span>
                    </strong>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: "" }}>
                      ”) in connection with the Website and Application or in
                      connection with the events associated with the
                      Application, on any third-party website or platform. In
                      addition, we have no control over, and shall have no
                      liability for, any damages resulting from the use
                      (including without limitation republication) or misuse by
                      any third party information made public through the
                      Website or Application. IF YOU CHOOSE TO SUBMIT TO US, OR
                      OTHERWISE MAKE ANY USER CONTENT PUBLICLY AVAILABLE, YOU DO
                      SO AT YOUR OWN RISK AND WE SHALL HAVE NO LIABILITY
                      THEREFOR. You agree that you will not, and will not permit
                      anyone else to, directly or indirectly:
                    </span>
                  </span>
                  <ol style={{ listStyleType: "undefined" }}>
                    <li>
                      <span
                        style={{
                          fontFamily: '"Times New Roman",serif',
                          color: "black",
                          fontWeight: "normal",
                          textDecoration: "none underline",
                          verticalAlign: "baseline",
                        }}
                      >
                        <span style={{ fontSize: 16 }}>
                          Transmit any User Content that is unlawful, harmful,
                          threatening, abusive, hateful, obscene, harassing,
                          tortious, defamatory, libelous, slanderous,
                          pornographic, profane, vulgar, offensive, lewd,
                          invasive of another’s privacy or racially, ethnically
                          or otherwise objectionable;
                        </span>
                      </span>
                    </li>
                    <li>
                      <span
                        style={{
                          fontFamily: '"Times New Roman",serif',
                          color: "black",
                          fontWeight: "normal",
                          textDecoration: "none underline",
                          verticalAlign: "baseline",
                        }}
                      >
                        <span style={{ fontSize: 16 }}>
                          Transmit any User Content: (i) that you do not have
                          the right to Transmit, under any law or contractual or
                          fiduciary relationships, including, without
                          limitation, any inside information or proprietary or
                          confidential information; (ii) that infringes any
                          patent, copyright, trademark or other intellectual
                          property right or misappropriates any trade secret or
                          right of privacy of any third-party; (iii) that
                          constitutes unsolicited or unauthorized advertising or
                          promotional materials, “spam,” “chain letters,” or
                          pyramid schemes; or (iv) that contains any software
                          routine, code, instruction or virus that is designed
                          to disable, delete, modify, damage or erase software,
                          hardware or data;
                        </span>
                      </span>
                    </li>
                    <li>
                      <span
                        style={{
                          fontFamily: '"Times New Roman",serif',
                          color: "black",
                          fontWeight: "normal",
                          textDecoration: "none underline",
                          verticalAlign: "baseline",
                        }}
                      >
                        <span style={{ fontSize: 16 }}>
                          Forge headers or otherwise manipulate identifiers in
                          order to disguise any User Content Transmitted through
                          the Website or Application.
                        </span>
                      </span>
                    </li>
                  </ol>
                </li>
              </ol>
              <p
                style={{
                  marginTop: "12.0pt",
                  marginRight: "0cm",
                  marginBottom: "12.0pt",
                  marginLeft: "0cm",
                  textIndent: "36.0pt",
                  fontSize: 13,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  textAlign: "justify",
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman",serif',
                    color: "black",
                    fontWeight: "normal",
                    textDecoration: "none underline",
                    verticalAlign: "baseline",
                  }}
                >
                  <span style={{ fontSize: 16 }}>
                    Although we have no obligation to screen, edit, or monitor
                    User Content, we reserve the right, and have absolute
                    discretion, to remove, screen or edit User Content posted or
                    stored on the Website and Application at any time and for
                    any reason, and you are solely responsible for creating
                    backup copies of and replacing any User Content you post or
                    store on the Website or Application at your sole cost and
                    expense.
                  </span>
                </span>
              </p>
              <ol
                style={{
                  listStyleType: "undefined",
                  marginLeft: "0cmundefined",
                }}
              >
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      Rights in User Content. We do not claim any ownership
                      interest in your User Content. However, by uploading,
                      posting or submitting User Content to the Website or
                      Application or to our pages or feeds on third party social
                      media platforms, you hereby grant Escapely a nonexclusive,
                      royalty-free, worldwide, perpetual, irrevocable and fully
                      sublicensable right and license to use, reproduce, modify,
                      adapt, publish, translate, create derivative works from,
                      distribute, perform and publicly display your User
                      Content, in whole or in part, in any manner or media and
                      for any purpose whatsoever at our sole discretion,
                      including, without limitation, for publicity, promotional,
                      advertising, trade, business, illustration, artistic and
                      other commercial and noncommercial purposes, however,
                      Escapely will only share personal information that you
                      provide in accordance with our Privacy Policy. You
                      acknowledge and agree that Escapely may disclose or use
                      any User Content that you Transmit for any purposes
                      permitted under applicable law, including, but not limited
                      to: (a) enforcing this Agreement; (b) complying with any
                      laws, regulations or rules of any federal, state, or local
                      government or agency; (c) responding to claims that any
                      User Content violates the rights of third parties; or (d)
                      protecting the rights or property of Escapely, its
                      customers, or the public.
                    </span>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      Feedback. &nbsp;Separate and apart from User Content, you
                      can submit questions, comments, suggestions, ideas,
                      original or creative materials or other information about
                      Escapely, the Website, Application or the products, or
                      services (collectively, “
                    </span>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span style={{ fontSize: 16 }}>Feedback</span>
                    </strong>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      ”). Feedback is non-confidential and shall become the sole
                      property of Escapely. Escapely shall own exclusive rights,
                      including, without limitation, all intellectual property
                      rights, in and to such Feedback and shall be entitled to
                      the unrestricted use and dissemination of this Feedback
                      for any purpose, commercial or otherwise, without
                      acknowledgment or compensation to you.
                    </span>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16, background: "white" }}>
                      Term and Termination
                    </span>
                  </span>
                  .
                  <ol style={{ listStyleType: "undefined" }}>
                    <li>
                      The term of Agreement commences when you acknowledge your
                      acceptance and will continue in effect until terminated by
                      you or Escapely as set forth in this Section&nbsp;15.
                    </li>
                    <li>
                      You may terminate this Agreement by deleting the
                      Application and all copies thereof from your Device.
                    </li>
                    <li>
                      Escapely may terminate this Agreement at any time without
                      notice if it ceases to support the Application or Website,
                      which Escapely may do in its sole discretion. In addition,
                      this Agreement will terminate immediately and
                      automatically without any notice if you violate any of the
                      terms and conditions of this Agreement.
                    </li>
                    <li>
                      Upon termination:
                      <ol style={{ listStyleType: "undefined" }}>
                        <li>
                          all rights granted to you under this Agreement will
                          also terminate; and
                        </li>
                        <li>
                          you must cease all use of the Application and delete
                          all copies of the Application from your Device and
                          account.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Termination will not limit any of Escapely’s rights or
                      remedies at law or in equity.
                    </li>
                  </ol>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16, background: "white" }}>
                      Disclaimer of Warranties
                    </span>
                  </span>
                  . THE APPLICATION AND RELATED PRODUCTS ON OUR WEBSITE ARE
                  PROVIDED TO END USER “AS IS” AND “AS AVAILABLE” AND WITH ALL
                  FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE
                  MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, ESCAPELY, ON
                  ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND
                  THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY
                  DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY,
                  OR OTHERWISE, WITH RESPECT TO THE APPLICATION, INCLUDING ALL
                  IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                  PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND
                  WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF
                  PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO
                  THE FOREGOING, ESCAPELY PROVIDES NO WARRANTY OR UNDERTAKING,
                  AND MAKES NO REPRESENTATION OF ANY KIND THAT THE APPLICATION
                  WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE
                  COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS,
                  SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY
                  PERFORMANCE OR RELIABILITY STANDARDS, OR BE ERROR-FREE, OR
                  THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED. YOU
                  ACKNOWLEDGE THAT YOUR USE OF THE WEBSITE AND APPLICATION IS AT
                  YOUR SOLE RISK. ESCAPELY DOES NOT WARRANT THAT YOUR USE OF THE
                  WEBSITE OR APPLICATION IS LAWFUL IN ANY PARTICULAR
                  JURISDICTION, AND ESCAPELY SPECIFICALLY DISCLAIMS SUCH
                  WARRANTIES.
                </li>
              </ol>
              <p
                style={{
                  marginTop: "12.0pt",
                  marginRight: "0cm",
                  marginBottom: "12.0pt",
                  marginLeft: "0cm",
                  textIndent: "72.0pt",
                  fontSize: 13,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  textAlign: "justify",
                }}
              >
                SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS
                ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE
                STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE
                EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.
              </p>
              <ol
                style={{
                  listStyleType: "undefined",
                  marginLeft: "0cmundefined",
                }}
              >
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16, background: "white" }}>
                      Limitation of Liability
                    </span>
                  </span>
                  . TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                  EVENT WILL ESCAPELY OR ITS AFFILIATES, OR ANY OF ITS OR THEIR
                  RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE ANY LIABILITY
                  ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE
                  WEBSITE, APPLICATION OR THE CONTENT AND SERVICES FOR:
                  <ol style={{ listStyleType: "undefined" }}>
                    <li>
                      PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF
                      SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF
                      GOODWILL, BUSINESS INTERRUPTION, COMPUTER OR MOBILE DEVICE
                      OR APPLICATION FAILURE OR MALFUNCTION, OR ANY OTHER
                      CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL,
                      OR PUNITIVE DAMAGES;
                    </li>
                    <li>
                      DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE
                      AMOUNT ACTUALLY PAID BY YOU FOR THE APPLICATION.
                    </li>
                  </ol>
                </li>
              </ol>
              <p
                style={{
                  marginTop: "12.0pt",
                  marginRight: "0cm",
                  marginBottom: "12.0pt",
                  marginLeft: "0cm",
                  textIndent: "72.0pt",
                  fontSize: 13,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  textAlign: "justify",
                }}
              >
                THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE
                OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR
                OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE
                FORESEEABLE OR ESCAPELY WAS ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES. THE FOREGOING LIMITATIONS SHALL APPLY EVEN IN THE EVENT
                YOUR REMEDIES HEREUNDER FAIL OF THEIR ESSENTIAL PURPOSE, AND THE
                FOREGOING SHALL CONSTITUTE ESCAPELY’S SOLE LIABILITY AND
                OBLIGATION IN RESPECT HEREOF. SOME JURISDICTIONS DO NOT ALLOW
                CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE
                LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.
              </p>
              <p
                style={{
                  marginTop: "12.0pt",
                  marginRight: "0cm",
                  marginBottom: "12.0pt",
                  marginLeft: "0cm",
                  textIndent: "72.0pt",
                  fontSize: 13,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  textAlign: "justify",
                }}
              >
                IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE YOUR RIGHTS
                UNDER CALIFORNIA CIVIL CODE 1542, WHICH STATES “A GENERAL
                RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING
                PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT
                THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR
                HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH
                THE DEBTOR OR RELEASED PARTY.”
              </p>
              <div
                style={{
                  marginTop: "6.0pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 16,
                  fontFamily: '"Arial",sans-serif',
                  color: "black",
                }}
              >
                <ol
                  style={{
                    marginBottom: "0cm",
                    listStyleType: "undefined",
                    marginLeft: "0cmundefined",
                  }}
                >
                  <li
                    style={{
                      marginTop: "6.0pt",
                      marginRight: "0cm",
                      marginBottom: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Arial",sans-serif',
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman",serif',
                        color: "black",
                        fontWeight: "normal",
                        textDecoration: "none underline",
                        verticalAlign: "baseline",
                      }}
                    >
                      <strong>
                        <span style={{ fontSize: 16 }}>
                          ARBITRATION AND CLASS ACTION WAIVER. PLEASE READ THE
                          FOLLOWING SECTION CAREFULLY BECAUSE IT REQUIRES YOU TO
                          ARBITRATE CERTAIN DISPUTES AND CLAIMS WITH ESCAPELY
                          AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF
                          FROM US.
                        </span>
                      </strong>
                    </span>
                  </li>
                </ol>
              </div>
              <p
                style={{
                  marginTop: "12.0pt",
                  marginRight: "0cm",
                  marginBottom: "12.0pt",
                  marginLeft: "0cm",
                  textIndent: "36.0pt",
                  fontSize: 13,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  textAlign: "justify",
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman",serif',
                    color: "black",
                    fontWeight: "normal",
                    textDecoration: "none underline",
                    verticalAlign: "baseline",
                  }}
                >
                  <span style={{ fontSize: 16 }}>
                    YOU AND ESCAPELY AGREE THAT ANY DISPUTE, CLAIM OR
                    CONTROVERSY ARISING OUT OF OR RELATING IN ANY WAY TO THIS
                    AGREEMENT, OR THE WEBSITE, APPLICATION, AND RELATED PRODUCTS
                    AND SERVICES SHALL BE FINALLY DECIDED BY BINDING ARBITRATION
                    UNDER THE RULES OF THE AMERICAN ARBITRATION ASSOCIATION
                    GOVERNING CONSUMER DISPUTES APPLYING NEVADA LAW.&nbsp;
                  </span>
                </span>
              </p>
              <p
                style={{
                  marginTop: "12.0pt",
                  marginRight: "0cm",
                  marginBottom: "12.0pt",
                  marginLeft: "0cm",
                  textIndent: "36.0pt",
                  fontSize: 13,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  textAlign: "justify",
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman",serif',
                    color: "black",
                    fontWeight: "normal",
                    textDecoration: "none underline",
                    verticalAlign: "baseline",
                  }}
                >
                  <span style={{ fontSize: 16 }}>
                    Arbitration uses a single, neutral arbitrator to decide a
                    dispute (instead of a judge or jury); arbitration allows for
                    more limited discovery than in a court case; and the
                    arbitration process and result is subject to very limited
                    review by courts. In an arbitration you have the right, at
                    your expense, to be represented by an attorney of your
                    choosing. Arbitrators can award the same damages and relief
                    under this Agreement that a court can award under this
                    Agreement. You and Escapely agree that any in-person
                    arbitral hearing would occur in the United States in the
                    same county and state as your billing address. Escapely
                    further agrees that your filing fee for an arbitration will
                    be capped at the amount set by the American Arbitration
                    Association. You agree that, by agreeing to this Agreement,
                    the U.S. Federal Arbitration Act governs the interpretation
                    and enforcement of this provision, and that you and Escapely
                    are each waiving the right to a trial by jury or to
                    participate in a class action. This arbitration provision
                    shall survive termination of this Agreement and the
                    termination of your use of the Website, Application,
                    products and services. REGARDLESS OF THE FORUM, YOU AND
                    ESCAPELY AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER
                    ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A
                    PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                    REPRESENTATIVE PROCEEDING. Further, unless both you and
                    Escapely agree otherwise, the arbitrator may not join or
                    consolidate more than one person’s claims with your claims,
                    and may not otherwise preside over any form of a
                    representative or class proceeding. If this specific
                    provision is found to be unenforceable, then the entirety of
                    this arbitration provision shall be null and void. The
                    arbitrator may award declaratory or injunctive relief only
                    in favor of the individual party seeking relief and only to
                    the extent necessary to provide relief warranted by that
                    party’s individual claim.
                  </span>
                </span>
              </p>
              <ol
                style={{
                  listStyleType: "undefined",
                  marginLeft: "0cmundefined",
                }}
              >
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      Changes to these Terms and Conditions. &nbsp;We may revise
                      and update this Agreement from time to time in our sole
                      discretion. All changes are effective immediately when we
                      post them, and apply to all access to and use of the
                      Application thereafter. However, any changes to the
                      dispute resolution provisions will not apply to any
                      disputes for which the parties have actual notice on or
                      before the date the change is posted on the Application.
                      Your continued use of the Application following the
                      posting of a revised Agreement means that you accept and
                      agree to the changes.&nbsp;
                    </span>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16, background: "white" }}>
                      Indemnification
                    </span>
                  </span>
                  . You agree to indemnify, defend, and hold harmless Escapely
                  and its officers, directors, employees, agents, affiliates,
                  successors, and assigns from and against any and all losses,
                  damages, liabilities, deficiencies, claims, actions,
                  judgments, settlements, interest, awards, penalties, fines,
                  costs, or expenses of whatever kind, including reasonable
                  attorneys’ fees, arising from or relating to your use or
                  misuse of the (i) the Application, (ii) the Website, (iii) any
                  product or services offered through the Application or
                  Website, (iv) and your breach of this Agreement, including but
                  not limited to the content you submit or make available
                  through this Application, and your acts or omissions while
                  using the Application.
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16, background: "white" }}>
                      Export Regulation
                    </span>
                  </span>
                  . The Application may be subject to US export control laws,
                  including the Export Control Reform Act and its associated
                  regulations. You shall not, directly or indirectly, export,
                  re-export, or release the Application to, or make the
                  Application accessible from, any jurisdiction or country to
                  which export, re-export, or release is prohibited by law,
                  rule, or regulation. You shall comply with all applicable
                  federal laws, regulations, and rules, and complete all
                  required undertakings (including obtaining any necessary
                  export license or other governmental approval), prior to
                  exporting, re-exporting, releasing, or otherwise making the
                  Application available outside the US.
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16, background: "white" }}>
                      Severability
                    </span>
                  </span>
                  . If any provision of this Agreement is illegal or
                  unenforceable under applicable law, the remainder of the
                  provision will be amended to achieve as closely as possible
                  the effect of the original term and all other provisions of
                  this Agreement will continue in full force and effect.
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16, background: "white" }}>
                      Governing Law
                    </span>
                  </span>
                  . This Agreement is governed by and construed in accordance
                  with the internal laws of the State of Nevada without giving
                  effect to any choice or conflict of law provision or rule. Any
                  legal suit, action, or proceeding arising out of or related to
                  this Agreement or the Application shall be instituted
                  exclusively in the federal courts of the United States or the
                  courts of the State of Nevada in each case located in Clark
                  County, Nevada. You waive any and all objections to the
                  exercise of jurisdiction over you by such courts and to venue
                  in such courts.
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16, background: "white" }}>
                      Limitation of Time to File Claims
                    </span>
                  </span>
                  . ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
                  RELATING TO THIS AGREEMENT OR THE APPLICATION MUST BE
                  COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
                  ACCRUES OTHERWISE SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY
                  BARRED.
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16, background: "white" }}>
                      Entire Agreement
                    </span>
                  </span>
                  . This Agreement, the Website Terms of Use, the Waiver
                  (attached hereto), and our Privacy Policy constitute the
                  entire agreement between you and Escapely with respect to the
                  Application and supersede all prior or contemporaneous
                  understandings and agreements, whether written or oral, with
                  respect to the Application. In the case of any conflict
                  between this Agreement, the Website Terms of Use and the
                  Waiver, the following order of priority shall be utilized: (1)
                  The Waiver; (2) this Agreement; and (3) the Website Terms of
                  Use.
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16, background: "white" }}>
                      Waiver
                    </span>
                  </span>
                  . No failure to exercise, and no delay in exercising, on the
                  part of either party, any right or any power hereunder shall
                  operate as a waiver thereof, nor shall any single or partial
                  exercise of any right or power hereunder preclude further
                  exercise of that or any other right hereunder. In the event of
                  a conflict between this Agreement and any applicable purchase
                  or other terms, the terms of this Agreement shall govern.
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      Notice. &nbsp;This Application is operated by Escapely LLC
                      (Adventure Box), Escapely Virtual LLC (Virtual Team
                      Building), and Escapely Live LLC (Scavenger Quest and
                      In-Person Team Building). All feedback, questions,
                      comments, requests for technical support, and other
                      communications relating to the Application should be
                      directed to the contact listed below.&nbsp;
                    </span>
                  </span>
                </li>
              </ol>
              <p
                style={{
                  marginTop: "12.0pt",
                  marginRight: "0cm",
                  marginBottom: "12.0pt",
                  marginLeft: "0cm",
                  textIndent: "0cm",
                  fontSize: 13,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  textAlign: "justify",
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman",serif',
                    color: "black",
                    fontWeight: "normal",
                    textDecoration: "none underline",
                    verticalAlign: "baseline",
                  }}
                >
                  <span style={{ fontSize: 16 }}>
                    If regarding Escapely® Adventure Box:
                  </span>
                </span>
              </p>
              <table>
                <tr>
                  <td style={{ width: "40%" }}>
                    <p>By Mail:</p>
                  </td>
                  <td>
                    <p>Escapely LLC</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ display: "flex" }}>
                    <p>Attn:</p>
                  </td>
                  <td>
                    <p>
                      Escapely Adventure Box Application Terms and Conditions
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ display: "flex" }}>
                    <p>By Mail:</p>
                  </td>
                  <td>
                    <p>
                      10801 W Charleston Blvd. Ste 690
                      <br />
                      Las Vegas, NV 89135
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ display: "flex" }}>
                    <p>By Email:</p>
                  </td>
                  <td>
                    <p>
                      <a
                        style={{ color: "rgb(0, 0, 0)" }}
                        href="mailto:privacy@escapely.com"
                      >
                        privacy@escapely.com
                      </a>
                    </p>
                  </td>
                </tr>
              </table>
              <p
                style={{
                  marginTop: "12.0pt",
                  marginRight: "0cm",
                  marginBottom: "12.0pt",
                  marginLeft: "0cm",
                  textIndent: "0cm",
                  fontSize: 13,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman",serif',
                    color: "black",
                    fontWeight: "normal",
                    textDecoration: "none underline",
                    verticalAlign: "baseline",
                  }}
                >
                  <span style={{ fontSize: 16 }}>
                    If regarding Escapely® Scavenger Quest:
                  </span>
                </span>
              </p>
              <table>
                <tr>
                  <td style={{ width: "40%" }}>
                    <p>By Mail:</p>
                  </td>
                  <td>
                    <p>Escapely Live LLC</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ display: "flex" }}>
                    <p>Attn:</p>
                  </td>
                  <td>
                    <p>
                      Escapely Scavenger Quest Application Terms and Conditions
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ display: "flex" }}>
                    <p>By Mail:</p>
                  </td>
                  <td>
                    <p>
                      10801 W Charleston Blvd. Ste 690
                      <br />
                      Las Vegas, NV 89135
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ display: "flex" }}>
                    <p>By Email:</p>
                  </td>
                  <td>
                    <p>
                      <a
                        style={{ color: "rgb(0, 0, 0)" }}
                        href="mailto:privacy@escapely.com"
                      >
                        privacy@escapely.com
                      </a>
                    </p>
                  </td>
                </tr>
              </table>
              <p
                style={{
                  marginTop: "12.0pt",
                  marginRight: "0cm",
                  marginBottom: "12.0pt",
                  marginLeft: "0cm",
                  textIndent: "0cm",
                  fontSize: 13,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman",serif',
                    color: "black",
                    fontWeight: "normal",
                    textDecoration: "none underline",
                    verticalAlign: "baseline",
                  }}
                >
                  <span style={{ fontSize: 16 }}>
                    If regarding Escapely® Virtual Team Building:
                  </span>
                </span>
              </p>
              <table>
                <tr>
                  <td style={{ width: "40%" }}>
                    <p>By Mail:</p>
                  </td>
                  <td>
                    <p>Escapely Virtual LLC</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ display: "flex" }}>
                    <p>Attn:</p>
                  </td>
                  <td>
                    <p>
                      Escapely Virtual Team Building Application Terms and
                      Conditions
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ display: "flex" }}>
                    <p>By Mail:</p>
                  </td>
                  <td>
                    <p>
                      10801 W Charleston Blvd. Ste 690
                      <br />
                      Las Vegas, NV 89135
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ display: "flex" }}>
                    <p>By Email:</p>
                  </td>
                  <td>
                    <p>
                      <a
                        style={{ color: "rgb(0, 0, 0)" }}
                        href="mailto:privacy@escapely.com"
                      >
                        privacy@escapely.com
                      </a>
                    </p>
                  </td>
                </tr>
              </table>
              <p
                style={{
                  marginTop: "12.0pt",
                  marginRight: "0cm",
                  marginBottom: "12.0pt",
                  marginLeft: "0cm",
                  textIndent: "0cm",
                  fontSize: 13,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman",serif',
                    color: "black",
                    fontWeight: "normal",
                    textDecoration: "none underline",
                    verticalAlign: "baseline",
                  }}
                >
                  <span style={{ fontSize: 16 }}>
                    If regarding Escapely® In-Person Team Building:
                  </span>
                </span>
              </p>
              <table>
                <tr>
                  <td style={{ width: "40%" }}>
                    <p>By Mail:</p>
                  </td>
                  <td>
                    <p>Escapely Live LLC</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ display: "flex" }}>
                    <p>Attn:</p>
                  </td>
                  <td>
                    <p>
                      Escapely In-Person Team Building Application Terms and
                      Conditions
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ display: "flex" }}>
                    <p>By Mail:</p>
                  </td>
                  <td>
                    <p>
                      10801 W Charleston Blvd. Ste 690
                      <br />
                      Las Vegas, NV 89135
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ display: "flex" }}>
                    <p>By Email:</p>
                  </td>
                  <td>
                    <p>
                      <a
                        style={{ color: "rgb(0, 0, 0)" }}
                        href="mailto:privacy@escapely.com"
                      >
                        privacy@escapely.com
                      </a>
                    </p>
                  </td>
                </tr>
              </table>
              <p
                style={{
                  // marginTop: "12.0pt",
                  // marginRight: "0cm",
                  // marginBottom: "12.0pt",
                  // marginLeft: "36.0pt",
                  textIndent: "0cm",
                  fontSize: 13,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 21 }}>WAIVER</span>
                </strong>
              </p>
              <p
                style={{
                  marginTop: "12.0pt",
                  marginRight: "0cm",
                  marginBottom: "12.0pt",
                  marginLeft: "36.0pt",
                  textIndent: "0cm",
                  fontSize: 13,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  textAlign: "center",
                }}
              >
                <strong>
                  <span style={{ fontSize: 21 }}>
                    Escapely®{" "}
                    <span
                      style={{
                        fontFamily: '"Times New Roman",serif',
                        color: "black",
                        fontWeight: "normal",
                        textDecoration: "none underline",
                        verticalAlign: "baseline",
                      }}
                    >
                      <strong>
                        Release and Waiver of Liability, &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp;Assumption of the Risk, Indemnity, Hold
                        Harmless Agreement, and Consent to Use of Name, Voice
                        and Likeness
                      </strong>
                    </span>
                  </span>
                </strong>
              </p>
              <p
                style={{
                  marginTop: "6.0pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "0cm",
                  fontSize: 16,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  textAlign: "justify",
                }}
              >
                BY CLICKING THE “ACCEPT” BUTTON, YOU (A) ACKNOWLEDGE THAT YOU
                HAVE READ AND UNDERSTAND THIS ESCAPELY® RELEASE AND WAIVER OF
                LIABILITY, &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;ASSUMPTION OF THE RISK,
                INDEMNITY, HOLD HARMLESS AGREEMENT, AND CONSENT TO USE OF NAME,
                VOICE AND LIKENESS (“WAIVER”); (B) REPRESENT THAT YOU ARE 18
                YEARS OF AGE OR OLDER, OR THE AGE OF MAJORITY IN YOUR
                JURISDICTION; AND (C) ACCEPT THIS WAIVER AND AGREE THAT YOU ARE
                LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS,
                DO NOT USE THE APPLICATION.
              </p>
              <ol
                style={{ listStyleType: "decimal", marginLeft: "0cmundefined" }}
              >
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      You acknowledge that, by agreeing to this Escapely®
                      Release and Waiver of Liability, Assumption of the Risk,
                      Indemnity, Hold Harmless Agreement, and Consent to Use of
                      Name, Voice and Likeness (“
                    </span>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span style={{ fontSize: "" }}>Waiver</span>
                    </strong>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      ”), by clicking the “accept” button, you are giving up
                      your right to make a claim against, or sue, Escapely
                      Virtual LLC; Escapely Live LLC; Escapely Games LLC; and
                      Escapely LLC (collectively, “
                    </span>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span style={{ fontSize: 16 }}>Escapely</span>
                    </strong>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>,” “</span>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span style={{ fontSize: 16 }}>we</span>
                    </strong>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>,” “</span>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span style={{ fontSize: 16 }}>us</span>
                    </strong>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>,” or “</span>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span style={{ fontSize: 16 }}>our</span>
                    </strong>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      ”) for any accident or injury occurring while
                      participating in activities provided by Escapely,
                      including but not limited to in person or online escape
                      room type puzzles, scavenger hunts, or other games or
                      entertainment. You understand that you are giving up these
                      rights on your own behalf and on behalf of your family,
                      heirs, executors, personal representatives, successors and
                      assigns. Specifically, you agree to release, hold
                      harmless, defend and indemnify Escapely, including its
                      subsidiaries and affiliates, and their respective
                      principals, agents, officers, directors, managers,
                      employees, agents, sponsors, sanctioners, guests and
                      participants (collectively, “
                    </span>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span style={{ fontSize: 16 }}>Releasees</span>
                    </strong>
                  </span>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      ”) from any and every claim, demand or cause of action of
                      any kind or nature, and any cost, loss, damage or expense,
                      arising from or related to your participation in any
                      activities provided or sponsored by Escapely. This
                      includes, but is not limited to, any claim or demands for
                      accidental death, dismemberment, or bodily injury to
                      yourself or others; for any and all losses or damages,
                      pain and suffering, loss of earning potential, loss of
                      consortium and/or punitive damages; or any damage to or
                      destruction of any personal or other property claimed to
                      arise out of any accident or occurrence during or in
                      connection with such activity.
                    </span>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      This Waiver is given in consideration of and as a
                      condition to allowing you to participate in the activities
                      provided by Escapely. This Waiver shall inure to and be
                      binding upon you and your respective heirs,
                      representatives, successors and permitted assigns. If any
                      provision of this Waiver is declared by a court of
                      competent jurisdiction to be invalid or unenforceable, the
                      remainder shall not be affected thereby.
                    </span>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      This Waiver extends to any and all claims you have or may
                      have against the Releasees, even if such claims result
                      from strict liability or negligence on the part of any or
                      all of the Releasees concerning the conditions,
                      qualification, instructions, rules and procedures under
                      which this activity is conducted, or from any other cause.
                      By clicking “accept”, you hereby state and represent that:
                    </span>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      You have been invited to ask, have asked, and have had
                      answered, all questions you have concerning your
                      participation in this Escapely provided activity and
                      concerning this Agreement and Waiver.
                    </span>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      You are in good physical condition and are of the age
                      required to participate in this activity.
                    </span>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      You understand there may be risks or dangers in
                      participating in escape room puzzles, scavenger hunts, and
                      related activities. You fully and unconditionally agree to
                      assume the risks and dangers involved with this activity.
                    </span>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      You agree to follow instructions, abide at all times by
                      all posted or provided rules and regulations, and to
                      exercise care as appropriate.
                    </span>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      You are voluntarily participating in this activity knowing
                      the existing weather, hazards, and other similar
                      conditions and factors associated with this activity.
                    </span>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      You will immediately notify Escapely of any accidents or
                      injuries, and will seek appropriate medical care.
                    </span>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      You have read this Agreement and Waiver and fully
                      understand that you have given up substantial rights by
                      signing it freely and voluntarily without any inducement,
                      assurance or guarantee being made.
                    </span>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      You unconditionally promise not to sue or maintain any
                      legal action against, or attempt to make any recovery from
                      Escapely or any of the Releasees in any civil litigation,
                      mediation, arbitration, or alternative dispute resolution
                      proceedings, for any and all losses, damages, and/or
                      injuries sustained during this activity.
                    </span>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      In consideration of and as a condition to allowing you to
                      participate in activities provided by Escapely, you hereby
                      consent to the use of your name, image voice, and
                      likeness, including photos and videos, audio and/or other
                      recordings and all accompanying personally identifiable
                      information provided by you to Escapely or that Escapely
                      obtains in the course of your participation in activities
                      provided by Escapely or, via all forms of media and
                      reproductions now known or hereafter devised worldwide, in
                      perpetuity, without notice, attribution or compensation to
                      you, your successors or assigns, or any other entity,
                      except where prohibited by law. You hereby irrevocably
                      consent to Escapely’s use of the copyrights, moral rights,
                      rights of publicity and integrity, and any other related
                      rights (or waiver of rights) that you may otherwise have
                      in the use of your name, image voice, and likeness,
                      including photos and videos, audio and/or other
                      recordings. You also release and discharge Escapely from
                      any and all claims and demands arising out of or in
                      connection with the use of any and all photos and videos,
                      audio and/or other recordings, including, but not limited
                      to, any and all claims for privacy rights, rights of
                      publicity, libel, slander, or misappropriation of your
                      name, voice, image or likeness.
                    </span>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Times New Roman",serif',
                      color: "black",
                      fontWeight: "normal",
                      textDecoration: "none underline",
                      verticalAlign: "baseline",
                    }}
                  >
                    <span style={{ fontSize: 16 }}>
                      This Waiver shall be binding on and shall inure to your
                      benefit and the benefit of Escapely and our respective
                      heirs, executors, administrators, legal representatives,
                      successors, and permitted assigns. All matters arising out
                      of or relating to this Waiver shall be governed by and
                      construed in accordance with the internal laws of the
                      State of Nevada without giving effect to any choice or
                      conflict of law provision or rule (whether of the State of
                      Nevada or any other jurisdiction). Any claim or cause of
                      action arising under this Waiver may be brought only in
                      the federal and state courts located in Nevada, and you
                      hereby irrevocably consent to the exclusive jurisdiction
                      of such courts.
                    </span>
                  </span>
                </li>
              </ol>
              <p
                style={{
                  marginTop: "12.0pt",
                  marginRight: "0cm",
                  marginBottom: "12.0pt",
                  marginLeft: "0cm",
                  textIndent: "0cm",
                  fontSize: 13,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  textAlign: "justify",
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman",serif',
                    color: "black",
                    fontWeight: "normal",
                    textDecoration: "none underline",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: 16 }}>
                      A general release does not extend to claims that the
                      creditor or releasing party does not know or suspect to
                      exist in his or her favor at the time of executing the
                      release and that, if known by him or her, would have
                      materially affected his or her settlement with the debtor
                      or released party.
                    </span>
                  </strong>
                </span>
              </p>
              <p
                style={{
                  marginTop: "12.0pt",
                  marginRight: "0cm",
                  marginBottom: "12.0pt",
                  marginLeft: "0cm",
                  textIndent: "0cm",
                  fontSize: 13,
                  fontFamily: '"Times New Roman",serif',
                  color: "black",
                  textAlign: "justify",
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman",serif',
                    color: "black",
                    fontWeight: "normal",
                    textDecoration: "none underline",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: 16 }}>
                      THIS WAIVER PROVIDES ESCAPELY WITH YOUR ABSOLUTE AND
                      UNCONDITIONAL CONSENT, WAIVER, AND RELEASE OF LIABILITY,
                      ALLOWING ESCAPELY TO PUBLICIZE, COMMERCIALLY EXPLOIT, AND
                      OTHERWISE USE YOUR LIKENESS, AND NAME AS SET OUT ABOVE. BY
                      SIGNING, YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTOOD
                      ALL OF THE TERMS OF THIS WAIVER AND THAT YOU ARE GIVING UP
                      SUBSTANTIAL LEGAL RIGHTS, INCLUDING THE RIGHT TO SUE
                      ESCAPELY.
                    </span>
                  </strong>
                </span>
              </p>
            </>
          </div>
        </DialogContentText>
      </DialogContent>
      {type === "signin" ? (
        <DialogActions style={{ padding: "16px" }}>{""}</DialogActions>
      ) : (
        <DialogActions
          style={{
            padding: "14px 24px",
            paddingTop: "16px",
            flexDirection: "column",
            alignItems: "center",
            maxHeight: "80px",
          }}
        >
          <p
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              color: "#000000",
              fontWeight: "700",
              fontSize: "14px",
            }}
          >
            Scroll down to accept.
          </p>
          <div style={{ display: "flex", width: "100%", marginLeft: "0" }}>
            {isTermsChecked ? (
              <Button
                variant="contained"
                className={"popup--btn"}
                onClick={() => {
                  console.log("accept all");
                  setIsTermsChecked(true);
                  setIsPopupOpen(false);
                }}
              >
                Accept
              </Button>
            ) : (
              <Button
                variant="contained"
                className={!bottom ? "popup--btn--disabled" : "popup--btn"}
                onClick={() => {
                  console.log("accept all");
                  setIsTermsChecked(true);
                  setIsPopupOpen(false);
                }}
                disabled={!bottom}
              >
                Accept
              </Button>
            )}
            <Button
              variant="contained"
              className="popup--btn--dont--accept"
              style={{ borderRadius: "0" }}
              onClick={() => {
                console.log("decline");
                setIsTermsChecked(false);
                setIsPopupOpen(false);
              }}
              // disabled={!bottom}
            >
              Decline
            </Button>
          </div>
          {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
        </DialogActions>
      )}
    </Dialog>
  );
};
