import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import FlipCameraAndroidIcon from "@material-ui/icons/FlipCameraAndroid";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";

const CaptureImages = ({ frames, color }) => {
  const videoRef = useRef(null);
  const chooseImgRef = useRef(null);
  const canvasContainerRef = useRef(null);
  const [takePictureModal, setTakePictureModal] = useState(false);
  const [frameName, setFrameName] = useState("");
  const [cameraAccessError, setCameraAccessError] = useState(false);
  const [imageData, setImageData] = useState("");
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [base64Url, setBase64Url] = useState("");
  const [switchCamera, setSwitchCamera] = useState(false);
  const [chooseFileOrTakePictureModal, setChooseFileOrTakePictureModal] =
    useState(false);
  const [chooseFileImage, setChooseFileImage] = useState("");
  const [isChooseImgRunning, setIsChooseImgRunning] = useState(false);

  const handleClickPictureForRear = () => {
    const canvas = document.createElement("canvas");
    const parentContainer = canvasContainerRef.current.parentElement;
    // const canvasWidth = parentContainer.offsetWidth;
    // const canvasHeight = parentContainer.offsetHeight;
    // const canvasWidth = 327;
    // const canvasHeight = 490;
    // const canvasWidth = 400;
    // const canvasHeight = 600;
    const canvasWidth = 834;
    const canvasHeight = 1250;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    const ctx = canvas.getContext("2d");

    // Draw the captured picture on the canvas
    const video = videoRef.current;
    const aspectRatio = video.videoWidth / video.videoHeight;
    let targetWidth = canvasWidth;
    let targetHeight = canvasWidth / aspectRatio;
    let xOffset = 0;
    let yOffset = (canvasHeight - targetHeight) / 2;

    // Check if the calculated targetHeight is less than the canvas height
    // If so, adjust the targetHeight and targetWidth
    if (targetHeight < canvasHeight) {
      targetHeight = canvasHeight;
      targetWidth = targetHeight * aspectRatio;
      xOffset = (canvasWidth - targetWidth) / 2;
      yOffset = 0;
    }

    ctx.drawImage(video, xOffset, yOffset, targetWidth, targetHeight);

    // Draw the overlay image on top of the captured picture
    const overlayImage = new Image();
    overlayImage.src = `${base64Url}`;
    overlayImage.setAttribute("crossOrigin", "anonymous");
    overlayImage.onload = () => {
      // Assuming overlayImage has the same aspect ratio as the canvas
      ctx.drawImage(overlayImage, 0, 0, canvasWidth, canvasHeight);

      // Access the captured and overlaid image data or perform other operations with the canvas
      const dataUrl = canvas.toDataURL("image/png", 1.0);
      stopCamera();
      setImageData(dataUrl);
      console.log(dataUrl);
    };
  };

  const handleClickPictureForSelfie = () => {
    const canvas = document.createElement("canvas");
    const parentContainer = canvasContainerRef.current.parentElement;
    // const canvasWidth = parentContainer.offsetWidth;
    // const canvasHeight = parentContainer.offsetHeight;
    // const canvasWidth = 400;
    // const canvasHeight = 600;
    // const canvasWidth = 327;
    // const canvasHeight = 490;
    const canvasWidth = 834;
    const canvasHeight = 1250;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    const ctx = canvas.getContext("2d");

    // Draw the captured picture on the canvas with horizontal mirror effect
    const video = videoRef.current;
    const aspectRatio = video.videoWidth / video.videoHeight;
    let targetWidth = canvasWidth;
    let targetHeight = canvasWidth / aspectRatio;
    let xOffset = 0;
    let yOffset = (canvasHeight - targetHeight) / 2;

    // Check if the calculated targetHeight is less than the canvas height
    // If so, adjust the targetHeight and targetWidth
    if (targetHeight < canvasHeight) {
      targetHeight = canvasHeight;
      targetWidth = targetHeight * aspectRatio;
      xOffset = (canvasWidth - targetWidth) / 2;
      yOffset = 0;
    }

    // Clear the canvas before drawing the video
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw the video on the canvas with horizontal mirror effect
    ctx.save();
    ctx.scale(-1, 1);
    ctx.translate(-canvasWidth, 0);
    ctx.drawImage(video, xOffset, yOffset, targetWidth, targetHeight);
    ctx.restore();

    // Draw the overlay image on top of the mirrored video
    const overlayImage = new Image();
    overlayImage.src = `${base64Url}`;
    overlayImage.setAttribute("crossOrigin", "anonymous");
    overlayImage.onload = () => {
      // Assuming overlayImage has the same aspect ratio as the canvas
      ctx.drawImage(overlayImage, 0, 0, canvasWidth, canvasHeight);

      // Access the captured, mirrored, and overlaid image data or perform other operations with the canvas
      const dataUrl = canvas.toDataURL("image/png", 1.0);
      stopCamera();
      setImageData(dataUrl);
      console.log(dataUrl);
    };
  };

  const handleClickPictureForChooseFile = () => {
    const canvas = document.createElement("canvas");
    const parentContainer = canvasContainerRef.current.parentElement;
    // const canvasWidth = parentContainer.offsetWidth;
    // const canvasHeight = parentContainer.offsetHeight;
    // const canvasWidth = 327;
    // const canvasHeight = 490;
    // const canvasWidth = 400;
    // const canvasHeight = 600;
    const canvasWidth = 834;
    const canvasHeight = 1250;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    const ctx = canvas.getContext("2d");

    const image = new Image();

    // Set the src attribute of the Image object to the created URL
    image.src = chooseFileImage;

    // Wait for the image to load
    image.onload = () => {
      // Calculate the aspect ratio of the image
      const aspectRatio = image.width / image.height;

      // Calculate the dimensions and position to draw the image on the canvas
      let targetWidth = canvasWidth;
      let targetHeight = canvasWidth / aspectRatio;
      let xOffset = 0;
      let yOffset = (canvasHeight - targetHeight) / 2;

      // Check if the calculated targetHeight is less than the canvas height
      // If so, adjust the targetHeight and targetWidth
      if (targetHeight < canvasHeight) {
        targetHeight = canvasHeight;
        targetWidth = targetHeight * aspectRatio;
        xOffset = (canvasWidth - targetWidth) / 2;
        yOffset = 0;
      }

      // Draw the image on the canvas
      ctx.drawImage(image, xOffset, yOffset, targetWidth, targetHeight);

      // Draw the overlay image on top of the captured picture
      const overlayImage = new Image();
      overlayImage.src = `${base64Url}`;
      overlayImage.setAttribute("crossOrigin", "anonymous");
      overlayImage.onload = () => {
        // Assuming overlayImage has the same aspect ratio as the canvas
        ctx.drawImage(overlayImage, 0, 0, canvasWidth, canvasHeight);

        // Access the captured and overlaid image data or perform other operations with the canvas
        const dataUrl = canvas.toDataURL("image/png", 1.0);
        // stopCamera();
        setImageData(dataUrl);
        console.log(dataUrl);
        setChooseFileImage("");
        setIsChooseImgRunning(true);
      };
    };
  };

  const handleUrlToBase64 = async (e, url) => {
    setFrameName(url);
    try {
      const response = await fetch(url, {
        // mode: "no-cors",
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
      });
      const blob = await response.blob();

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64Data = reader.result;
        setBase64Url(base64Data);
        console.log(base64Data);
      };
    } catch (error) {
      console.error("Error converting image to base64:", error);
    }
  };

  const handleCameraAccess = async () => {
    try {
      setIsCameraOpen(true);

      if (videoRef.current && videoRef.current.srcObject) {
        // Camera access is already granted, use the existing stream
        return;
      }

      const permissionStatus = await navigator.permissions.query({
        name: "camera",
      });
      if (
        permissionStatus.state === "granted" ||
        permissionStatus.state === "prompt"
      ) {
        // Camera permission is already granted or will be prompted
        const constraints = {
          audio: false,
          video: {
            facingMode: switchCamera ? "environment" : "user", // Rear or front camera
            // width: { ideal: 300 },
            // height: { ideal: 300 },
            // width: { ideal: "auto" },
            // height: { ideal: "auto" },
            width: { ideal: 1280 }, // Set the desired width (adjust as needed)
            height: { ideal: 720 }, // Set the desired height (adjust as needed)
          },
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        videoRef.current.srcObject = stream;
      } else {
        // Camera permission denied or unavailable
        setTakePictureModal(false);
        setCameraAccessError(true);
        setIsCameraOpen(false);
        setImageData("");
        setFrameName("");
        setBase64Url("");
      }
    } catch (error) {
      console.log(error.message);
      setTakePictureModal(false);
      setCameraAccessError(true);
      setIsCameraOpen(false);
      setImageData("");
      setFrameName("");
      setBase64Url("");
    }
  };

  const stopCamera = () => {
    if (videoRef.current) {
      const mediaStream = videoRef.current.srcObject;
      if (mediaStream) {
        const tracks = mediaStream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
        videoRef.current.srcObject = null;
        //   setIsCameraOpen(false);
      }
    }
  };

  const handlClosePictureModal = () => {
    stopCamera();
    setTakePictureModal(false);
    setIsCameraOpen(false);
    setImageData("");
    setBase64Url("");
    setFrameName("");
    setChooseFileImage("");
    setIsChooseImgRunning(false);
  };

  const handleDownloadPicture = () => {
    // Create a new anchor element
    const link = document.createElement("a");
    link.href = imageData;
    link.target = "_blank"; // Open the link in a new tab
    link.rel = "noopener noreferrer"; // Set security attributes when opening in a new tab
    link.download = "escapelyframes.png";

    // Attach the link to the document body
    document.body.appendChild(link);

    // Simulate a click event to trigger the download
    link.click();

    // Clean up by removing the link after a short delay
    setTimeout(() => {
      document.body.removeChild(link);
    }, 100);
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        const blob = await fetch(imageData).then((res) => res.blob());
        const file = new File([blob], "image.png", { type: blob.type });
        const filesArray = [file];

        await navigator.share({
          files: filesArray,
        });
        console.log("Image shared successfully!");
      } else {
        console.log("Web Share API not supported");
      }
    } catch (error) {
      console.error("Error sharing image:", error);
    }
  };

  const handleOnChangeInputImage = (e) => {
    setChooseFileImage(URL.createObjectURL(e.target.files[0]));
    setChooseFileOrTakePictureModal(false);
    setIsCameraOpen(true);
    setImageData("");
    setIsChooseImgRunning(true);
  };

  useEffect(() => {
    if (isCameraOpen) {
      stopCamera();
      handleCameraAccess();
    }
  }, [switchCamera]);
  return (
    <>
      {/* if any error occour in oppening the camera  */}
      <Dialog
        open={cameraAccessError}
        onClose={() => {
          setCameraAccessError(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Camera Access Denied!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Oops! It seems like you've denied camera access permission. Please
            go to your browser settings and grant permission to access the
            camera.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "16px 24px" }}>
          <Button
            className="dialog-btn-dismiss"
            autoFocus
            variant="contained"
            style={{ width: "100%" }}
            onClick={() => {
              setTakePictureModal(false);
              setCameraAccessError(false);
            }}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>

      {/* Chose a file or Take a Picture modal  */}
      <Dialog
        open={chooseFileOrTakePictureModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="access--code--modal create--join--team--modals"
      >
        <DialogTitle id="alert-dialog-title" className="access--code--required">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{"SELECT OPTION"}</span>
            <Button
              className="image--capture--modal--close--btn"
              autoFocus
              variant="contained"
              onClick={() => {
                setChooseFileOrTakePictureModal(false);
              }}
            ></Button>
          </div>
        </DialogTitle>
        <DialogContent>
          Please select an option from the available choices provided below.
        </DialogContent>
        <DialogActions
          style={{
            padding: "16px 24px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <input
            type="file"
            name=""
            id="choosefile"
            style={{ display: "none" }}
            onChange={(e) => {
              handleOnChangeInputImage(e);
            }}
          />
          <Button
            className="dialog-btn-dismiss margin--0"
            autoFocus
            variant="contained"
            style={{
              width: "100%",
              color: "#fffb00",
              // opacity: `${codeForJoin.length < 4 || btnLoad ? "0.7" : "1"}`,
            }}
            // disabled={codeForJoin.length < 4 || btnLoad}
            onClick={() => {
              document.getElementById("choosefile").click();
            }}
          >
            UPLOAD PHOTO
          </Button>
          <Button
            className="dialog-btn-dismiss leave--team--modal--btn"
            autoFocus
            variant="contained"
            style={{
              width: "100%",
              color: "#fffb00",
              // opacity: `${codeForJoin.length < 4 || btnLoad ? "0.7" : "1"}`,
            }}
            // disabled={codeForJoin.length < 4 || btnLoad}
            onClick={() => {
              handleCameraAccess();
              setChooseFileOrTakePictureModal(false);
            }}
          >
            TAKE PICTURE
          </Button>
        </DialogActions>
      </Dialog>

      {/* take Picture modal  */}
      <Dialog
        className="image--capture--modal"
        open={takePictureModal}
        onClose={() => {
          stopCamera();
          setTakePictureModal(false);
          setIsCameraOpen(false);
          setImageData("");
          setChooseFileImage("");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* if user Choose an image */}
        {chooseFileImage ? (
          <>
            {/* <DialogTitle id="alert-dialog-title"> */}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                className="image--capture--modal--close--btn"
                autoFocus
                variant="contained"
                onClick={() => {
                  // handlClosePictureModal();
                  setFrameName("");
                  setChooseFileImage(false);
                  setIsCameraOpen(false);
                  stopCamera();
                }}
              ></Button>
            </div>
            {/* </DialogTitle> */}
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{ textAlign: "center", margin: "auto" }}
              >
                <div
                  style={{
                    position: "relative",
                    // width: "100%",
                    width: "327px",
                    height: "490px",
                    margin: "16px auto",
                  }}
                  ref={canvasContainerRef}
                >
                  <img
                    src={frameName}
                    alt=""
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <img
                    ref={chooseImgRef}
                    src={chooseFileImage}
                    alt=""
                    srcSet=""
                    style={{
                      height: "490px",
                      width: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                padding: "16px 24px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <input
                type="file"
                name=""
                id="choosefile"
                style={{ display: "none" }}
                onChange={(e) => {
                  handleOnChangeInputImage(e);
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                  marginBottom: "1rem",
                }}
              >
                <PhotoLibraryIcon
                  onClick={() => {
                    document.getElementById("choosefile").click();
                  }}
                />
              </div>
              <Button
                className="dialog-btn-dismiss"
                autoFocus
                variant="contained"
                style={{ width: "100%" }}
                onClick={() => handleClickPictureForChooseFile()}
              >
                NEXT
              </Button>
            </DialogActions>
          </>
        ) : (
          // if user clicks on take picture with camera
          <>
            {/* if camera is not open  */}
            {!isCameraOpen ? (
              <>
                {" "}
                {/* <DialogTitle id="alert-dialog-title"> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="image--capture--modal--close--btn"
                    autoFocus
                    variant="contained"
                    onClick={() => {
                      handlClosePictureModal();
                    }}
                  ></Button>
                </div>
                {/* </DialogTitle> */}
                <DialogTitle id="alert-dialog-title">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>{"SELECT FRAME"}</span>{" "}
                  </div>
                </DialogTitle>
                <DialogContent className="scrollbar--frames">
                  <DialogContentText id="alert-dialog-description">
                    <div className="frames--container">
                      {frames.map((res) => {
                        return (
                          <img
                            src={res.location}
                            alt=""
                            srcset=""
                            className={`${
                              frameName === res.location
                                ? "frames--img selected--frame"
                                : "frames--img"
                            }`}
                            onClick={(e) => {
                              handleUrlToBase64(e, res.location);
                              // setFrameName(res.location);
                            }}
                          />
                        );
                      })}
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions style={{ padding: "16px 24px" }}>
                  <Button
                    className="dialog-btn-dismiss"
                    autoFocus
                    variant="contained"
                    style={{
                      width: "100%",
                      opacity: `${!frameName ? "0.6" : "1"}`,
                    }}
                    onClick={() => setChooseFileOrTakePictureModal(true)}
                    disabled={!frameName}
                  >
                    Next
                  </Button>
                </DialogActions>
              </>
            ) : (
              // if camera opend and picture clicked
              <>
                {imageData ? (
                  <>
                    {/* <DialogTitle id="alert-dialog-title"> */}
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        className="image--capture--modal--close--btn"
                        autoFocus
                        variant="contained"
                        onClick={() => {
                          handlClosePictureModal();
                        }}
                      ></Button>
                    </div>
                    {/* </DialogTitle> */}
                    <DialogContent>
                      <DialogContentText
                        id="alert-dialog-description"
                        style={{ textAlign: "center" }}
                      >
                        <img
                          src={imageData}
                          alt=""
                          srcset=""
                          style={{
                            width: "327px",
                            height: "490px",
                            margin: "16px auto",
                          }}
                        />
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ padding: "16px 24px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          marginBottom: "0",
                        }}
                      >
                        {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="/assets/shareicon.svg"
                        alt="shareicon"
                        style={{ width: "52px" }}
                        onClick={() => handleShare()}
                      />
                      <p
                        style={{
                          fontFamily: "var(--mono-medium)",
                          fontWeight: "400",
                        }}
                      >
                        Share your picture
                      </p>
                    </div> */}
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <input
                            type="file"
                            name=""
                            id="choosefile"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleOnChangeInputImage(e);
                            }}
                          />
                          {isChooseImgRunning ? (
                            <Button
                              className="dialog-btn-dismiss dialog-btn-dismiss"
                              // className="dialog-btn-dismiss--transparent dialog-btn-dismiss"
                              // className="dialog-btn-dismiss margin--top--1rem"
                              autoFocus
                              variant="contained"
                              style={{ width: "100%", marginRight: "0.5em" }}
                              onClick={() => {
                                document.getElementById("choosefile").click();
                              }}
                            >
                              CHANGE
                            </Button>
                          ) : (
                            <Button
                              className="dialog-btn-dismiss dialog-btn-dismiss"
                              // className="dialog-btn-dismiss--transparent dialog-btn-dismiss"
                              // className="dialog-btn-dismiss margin--top--1rem"
                              autoFocus
                              variant="contained"
                              style={{ width: "100%", marginRight: "0.5em" }}
                              onClick={() => {
                                setImageData("");
                                handleCameraAccess();
                              }}
                            >
                              Retake
                            </Button>
                          )}

                          <Button
                            className="dialog-btn-dismiss dialog-btn-dismiss"
                            // className="dialog-btn-dismiss--transparent dialog-btn-dismiss"
                            // className="dialog-btn-dismiss margin--top--1rem"
                            autoFocus
                            variant="contained"
                            style={{ width: "100%", marginLeft: "0.5em" }}
                            onClick={() => handleShare()}
                          >
                            Share
                          </Button>
                        </div>
                        <p className="save--photo--p">
                          To save your photo, press and hold the image and
                          select Save or Download.
                        </p>
                        {/* <Button
                      className="dialog-btn-dismiss margin--top--1rem"
                      // className="dialog-btn-dismiss margin--top--1rem"
                      autoFocus
                      variant="contained"
                      style={{ width: "100%" }}
                      // onClick={() => handleShare()}
                      onClick={() => handleDownloadPicture()}
                    >
                      SAVE PICTURE
                    </Button> */}
                      </div>
                    </DialogActions>
                  </>
                ) : (
                  // if cameta is open and picture is not clicked
                  <>
                    {/* <DialogTitle id="alert-dialog-title"> */}
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        className="image--capture--modal--close--btn"
                        autoFocus
                        variant="contained"
                        onClick={() => {
                          // handlClosePictureModal();
                          setFrameName("");
                          stopCamera();
                          setIsCameraOpen(false);
                        }}
                      ></Button>
                    </div>
                    {/* </DialogTitle> */}
                    <DialogContent>
                      <DialogContentText
                        id="alert-dialog-description"
                        style={{ textAlign: "center", margin: "auto" }}
                      >
                        <div
                          style={{
                            position: "relative",
                            width: "327px",
                            height: "490px",
                            margin: "16px auto",
                          }}
                          ref={canvasContainerRef}
                        >
                          <img
                            src={frameName}
                            alt=""
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "0",
                              // width: "100%",
                              height: "auto",
                              objectFit: "contain",
                              zIndex: "2",
                              height: "490px",
                              width: "327px",
                            }}
                          />
                          <video
                            ref={videoRef}
                            autoPlay
                            playsInline // Add the playsInline attribute for iOS Safari
                            style={{
                              height: "490px",
                              // width: "100%",
                              width: "327px",
                              objectFit: "cover",
                              objectPosition: "center",
                              transform: switchCamera ? "none" : "scaleX(-1)",
                              zIndex: "1",
                            }}
                          ></video>
                        </div>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        padding: "16px 24px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          // marginBottom: "1rem",
                        }}
                      >
                        <Button
                          className="dialog-btn-dismiss flip--button"
                          autoFocus
                          variant="contained"
                          style={{ width: "100%", marginRight: "0.5em" }}
                          onClick={() => {
                            setSwitchCamera(!switchCamera);
                          }}
                        >
                          <FlipCameraAndroidIcon />
                        </Button>
                        <Button
                          className="dialog-btn-dismiss"
                          autoFocus
                          variant="contained"
                          style={{ width: "100%", marginLeft: "0.5em" }}
                          onClick={() => {
                            switchCamera
                              ? handleClickPictureForRear()
                              : handleClickPictureForSelfie();
                          }}
                        >
                          TAKE PICTURE
                        </Button>
                      </div>
                    </DialogActions>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Dialog>
      {/* <Button
        className="btn-location"
        variant="contained"
        onClick={() => setTakePictureModal(true)}
        style={{ width: "100%" }}
      >
      Take a photo
      </Button> */}
      <div style={{ textAlign: "center", width: "100%" }}>
        {/* <AddAPhotoIcon
          className="photo--camera--icon"
          style={{ color: color }}
          onClick={() => setTakePictureModal(true)}
        /> */}
        <img
          className="photo--camera--icon"
          src={
            color !== "#000"
              ? "/assets/whitecamera.png"
              : "/assets/blackcamera.png"
          }
          alt=""
          srcSet=""
          onClick={() => setTakePictureModal(true)}
        />
      </div>
    </>
  );
};

export default CaptureImages;
