import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { withRouter, useHistory } from "react-router-dom";
import { APIURI, SOCKET_URI } from "../config";
import CreateJoinTeam from "./CreateJoinTeam";
import createMarkup from "../utils/CreateMarkup";
import CaptureImages from "./CaptureImages";
import Audio from "../utils/Audios/Audio";
import Loader from "../utils/Loader";
import Video from "../utils/Videos/Video";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import PodStartStory from "./PodStartStory";
import LogoutComponent from "./Logout";
import LeaderBoard from "./LeaderBoard";
import io from "socket.io-client";
import CongratsForPods from "./CongratsForPods";
const socketNotification = io(`${SOCKET_URI}/notification`, {
  reconnection: true, // Enable automatic reconnection
  reconnectionAttempts: 10, // Number of reconnection attempts
  reconnectionDelay: 2000, // Delay between reconnection attempts (in milliseconds)
});

const PodStory = () => {
  const [podDetails, setPodDetails] = useState([]);
  const [loadBtn, setLoadBtn] = useState(false);
  const [wrongAnswers, setWrongAnswers] = useState([]);
  const [isOnChangeRun, setIsOnChangeRun] = useState(false);
  const [load, setLoad] = useState(true);
  const [SceneDescription, setSceneDescription] = useState([]);
  const [totalAnswers, setTotalAnswers] = useState([]);
  const [emailCollectionField, setEmailCollectionField] = useState("");
  const [ansCount, setansCount] = useState(0);
  const [email, setEmail] = useState("");
  const [buttonLoad, setButtonLoad] = useState(false);
  const [solMSG, setSolMSG] = useState("");
  const [sceneDetails, setSceneDetails] = useState([]);
  const [frames, setFrames] = useState([]);
  const [togglePopoverArr, setTooglePopoverArr] = useState([]);
  const [togglePopoverMessage, setTooglePopoverMessage] = useState([]);
  const [togglePopover, setTooglePopover] = useState(false);
  const [arrErrorMessageCount, setarrErrorMessageCount] = useState([]);
  const [arrErrorMessage, setarrErrorMessage] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [isAccessCodeRequired, setIsAccessCodeRequired] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const [incorrectCodeError, setIncorrectCodeError] = useState("");
  const [hideContinueBtn, setHideContinueBtn] = useState("");
  const [sceneID, setSceneID] = useState("");
  const [sceneType, setSceneType] = useState("");
  const [isPodStartScreen, setIsPodStartScreen] = useState(false);
  const [isCreateJoinTeamScreen, setIsCreateJoinTeamScreen] = useState(false);
  const [wrongQr, setWrongQr] = useState(false);
  const [isSomeThingUpdated, setIsSomeThingUpdated] = useState(0);
  const [firstSceneDetails, setFirstSceneDetails] = useState("");
  const [teamInfo, setTeamInfo] = useState([]);
  const [isFirstSceneAvailable, setIsFirstSceneAvailable] = useState(false);
  const [podStatus, setPodStatus] = useState("");
  const [actionButtonLoad, setActionButtonLoad] = useState(true);
  const [isTeamCreator, setIsTeamCreator] = useState(false);
  const [showCongratsStory, setShowCongratsStory] = useState(false);
  const [pushNotificationModal, setPushNotificationModal] = useState(false);
  const [hasPodStarted, setHasPodStarted] = useState(false);
  const [pushNotificationData, setPushNotificationData] = useState("");
  const [teamPageDesc, setTeamPageDesc] = useState("");
  const [createJoinPageDesc, setCreateJoinPageDesc] = useState("");
  const [podNotificationsOnTime, setPodNotificationsOnTime] = useState([]);
  const [solutionAnswer, setSolutionAnswer] = useState([
    { _id: "", answer: "" },
  ]);
  const game_id = useParams();
  let submittedAnswerCount = 0;

  const accessToken = localStorage.getItem("accesstoken");
  let config;
  config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  let correctArr = [];
  let wrongArr = [];

  let errorMessage = [];
  let errorMessageCount = [];

  const handleFocus = (i, event, key) => {
    event.preventDefault();
    if (wrongAnswers.length > 0) {
      const wrong = wrongAnswers;
      // if (wrong[i] === key) {
      //   delete wrong[i];
      //   console.log("wrong in handle focus", wrong);
      //   setWrongAnswers(wrong);
      // }
    }
  };

  const handleIsSomethingUpdated = () => {
    setIsSomeThingUpdated(isSomeThingUpdated + 1);
    setShowCongratsStory(false);
    setIsCreateJoinTeamScreen(false);
    setIsPodStartScreen(false);
  };

  const handleValidateCode = () => {
    setIncorrectCodeError("");
    setLoadBtn(true);
    axios
      .post(
        `${APIURI}/web-pods/validatePod/${game_id.gameID}`,
        { accessCode: accessCode },
        config
      )
      .then((res) => {
        console.log(res);
        setLoadBtn(false);
        setIsAccessCodeRequired(false);
        setIsSomeThingUpdated(isSomeThingUpdated + 1);
      })
      .catch((err) => {
        console.log(err);
        setLoadBtn(false);
        setIncorrectCodeError("Invalid code. Codes are case sensitive.");
      });
  };

  const handleAnswerValidate = async (e) => {
    // setOnErrorId([]);
    setLoad(true);
    setButtonLoad(true);
    // console.log(arrErrorMessageCount);
    e.preventDefault();
    const dataAns = solutionAnswer;
    if (dataAns[0].answer === "") {
      setButtonLoad(false);
    }
    if (dataAns[0].answer !== "") {
      wrongArr = [];
      console.log(totalAnswers);
      console.log(dataAns);
      if (totalAnswers.length > 0) {
        await dataAns.forEach((answer) => {
          answer.answer = answer.answer.trim();
          console.log(answer, " please submit the button");
          axios
            .post(`${APIURI}/web-podanswer/validate/${sceneID}`, answer, config)
            .then((res) => {
              submittedAnswerCount++;
              if (res.data.Validation) {
                correctArr.push(answer._id);
                console.log(correctArr);
              } else {
                wrongArr.push(answer._id);
                console.log("lengthhhh:------ ", arrErrorMessageCount.length);
                // console.log(arrErrorMessageCount);
                // arrErrorMessage.forEach((wrong_answers,inddex)=>{
                //   console.log(wrong_answers)
                //   console.log(answer._id)
                // })
                // getErrorMessageCount(answer._id);
                // console.log(arrErrorMessageCount);
              }
              console.log("Submitted Count: ", submittedAnswerCount, ansCount);
              if (ansCount === submittedAnswerCount) {
                console.log("Wrong Answers", wrongArr);
                setWrongAnswers(wrongArr);
                setCorrectAnswers(correctArr);
                if (wrongArr.length === 0) {
                  // console.log("NEXT SCENE ID: ", nextScene);
                  setButtonLoad(false);
                  axios
                    .get(`${APIURI}/web-podanswer/complete/${sceneID}`, config)
                    .then((res) => {
                      console.log(res);
                      setIsSomeThingUpdated(isSomeThingUpdated + 1);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                  // if (nextScene !== 0 && nextScene !== null) {
                  //   axios
                  //     .get(
                  //       `${APIURI}/web-podanswer/complete/${sceneID}`,
                  //       config
                  //     )
                  //     .then((res) => {
                  //       console.log(
                  //         "move to next screen called here is the response",
                  //         res
                  //       );
                  //       window.location.href = `/solution:${gameId}/${nextScene}`;
                  //     });
                  //   setButtonLoad(false);
                  // } else {
                  //   window.location.href = `/congrats:${sceneID}`;
                  //   setButtonLoad(false);
                  // }
                }
                setButtonLoad(false);
              }
            })
            .catch((err) => {
              console.log(err);
              setButtonLoad(false);
            });
        });
        await setLoad(false);
      } else {
        console.log("No answers");
        setLoad(false);
        setButtonLoad(false);
        setIsSomeThingUpdated(isSomeThingUpdated + 1);
        // if (nextScene !== 0 && nextScene !== null) {
        //   // console.log("No answers Block IF");
        //   window.location.href = `/solution:${gameId}/${nextScene}`;
        // } else {
        //   // console.log("No answers Block Else");
        //   window.location.href = `/congrats:${sceneID}`;
        // }
      }
    }
    setLoad(false);
    setIsOnChangeRun(false);
  };
  const handleAnswerValidateContinue = async (e) => {
    setLoad(true);
    e.preventDefault();
    const dataAns = solutionAnswer;
    wrongArr = [];
    if (totalAnswers.length > 0) {
      await dataAns.forEach((answer) => {
        axios
          .post(`${APIURI}/web-podanswer/validate/${sceneID}`, answer, config)
          .then((res) => {
            submittedAnswerCount++;

            if (res.data.Validation) {
              correctArr.push(answer._id);
            } else {
              wrongArr.push(answer._id);
              console.log(arrErrorMessageCount.length);
              // getErrorMessageCount(answer._id);
            }
            console.log("Submitted Count: ", submittedAnswerCount, ansCount);
            if (ansCount === submittedAnswerCount) {
              console.log("Wrong Answers", wrongArr);
              setWrongAnswers(wrongArr);
              setCorrectAnswers(correctArr);
              if (wrongArr.length === 0) {
                // console.log("NEXT SCENE ID: ", nextScene);
                setButtonLoad(false);
                axios
                  .get(`${APIURI}/web-answer/complete/${sceneID}`, config)
                  .then((res) => {
                    console.log(res);
                    setIsSomeThingUpdated(isSomeThingUpdated + 1);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                // if (nextScene !== 0 && nextScene !== null) {
                //   axios
                //     .get(`${APIURI}/web-answer/complete/${sceneID}`, config)
                //     .then((res) => {
                //       console.log(
                //         "move to next screen called here is the response",
                //         res
                //       );
                //       window.location.href = `/solution:${gameId}/${nextScene}`;
                //     });
                //   // window.location.href = `/solution:${gameId}/${nextScene}`;
                //   setButtonLoad(false);
                // } else {
                //   axios
                //     .get(`${APIURI}/web-podanswer/complete/${sceneID}`, config)
                //     .then((res) => {
                //       console.log(
                //         "move to next screen called here is the response",
                //         res
                //       );
                //       // window.location.href = `/congrats:${sceneID}`;
                //       loadPod();
                //     });
                //   // window.location.href = `/congrats:${sceneID}`;
                //   setButtonLoad(false);
                // }
              }
              setButtonLoad(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setButtonLoad(false);
          });
      });
      await setLoad(false);
    } else {
      console.log("No answers");
      // setLoad(false);
      setButtonLoad(false);
      axios
        .get(`${APIURI}/web-podanswer/complete/${sceneID}`, config)
        .then((res) => {
          console.log(res);
          setIsSomeThingUpdated(isSomeThingUpdated + 1);
        })
        .catch((err) => {
          console.log(err);
        });
      // if (nextScene !== 0 && nextScene !== null) {
      //   // console.log("No answers Block IF");
      //   axios
      //     .get(`${APIURI}/web-podanswer/complete/${sceneID}`, config)
      //     .then((res) => {
      //       console.log("move to next screen called here is the response", res);
      //       window.location.href = `/solution:${gameId}/${nextScene}`;
      //     });
      //   // window.location.href = `/solution:${gameId}/${nextScene}`;
      // } else {
      //   // console.log("No answers Block Else");
      //   axios
      //     .get(`${APIURI}/web-podanswer/complete/${sceneID}`, config)
      //     .then((res) => {
      //       console.log("move to next screen called here is the response", res);
      //       window.location.href = `/congrats:${sceneID}`;
      //     });
      //   // window.location.href = `/congrats:${sceneID}`;
      // }
    }
    setLoad(false);
    setIsOnChangeRun(false);
  };

  function handleRedCrossClick(i, e, key) {
    e.preventDefault();
    console.log("red cross clicked!!");
    removeWrongAnsKeys(key);
    // const errorId = [...onErrorId];
    // if (!errorId.includes(key)) {
    //   errorId.push(key);
    // }
    // setOnErrorId(errorId);
    const values = [...solutionAnswer];
    values[i]._id = key;
    console.log(values[i].answer);
    values[i].answer = "";
    setSolutionAnswer(values);
    setIsOnChangeRun(true);
  }

  function getErrorMessageCount(answerID) {
    console.log(arrErrorMessageCount, arrErrorMessage, "countmessagebefore");
    for (let index = 0; index < arrErrorMessageCount.length; index++) {
      console.log(arrErrorMessageCount, "countmessage");
      if (arrErrorMessageCount[index][answerID] !== undefined) {
        let value = arrErrorMessageCount[index][answerID] + 1;
        if (
          value < arrErrorMessage[index][answerID].length &&
          arrErrorMessage[index][answerID].length % 2 !== 0
        ) {
          arrErrorMessageCount[index][answerID] = value;
          return arrErrorMessage[index][answerID][value];
        }
        if (
          value === arrErrorMessage[index][answerID].length &&
          arrErrorMessage[index][answerID].length % 2 !== 0
        ) {
          arrErrorMessageCount[index][answerID] = 0;
          return arrErrorMessage[index][answerID][0];
        }
        if (
          value < arrErrorMessage[index][answerID].length &&
          arrErrorMessage[index][answerID].length % 2 === 0
        ) {
          arrErrorMessageCount[index][answerID] = value;
          return arrErrorMessage[index][answerID][value];
        }
        if (
          value === arrErrorMessage[index][answerID].length &&
          arrErrorMessage[index][answerID].length % 2 === 0
        ) {
          arrErrorMessageCount[index][answerID] = 0;
          return arrErrorMessage[index][answerID][0];
        }
        console.log(arrErrorMessageCount);
        console.log(arrErrorMessageCount[index][answerID]);
      }
    }
  }
  const handleWrongAnswerMessage = (id) => {
    return getErrorMessageCount(id);
  };

  function removeWrongAnsKeys(key) {
    let arry = [...wrongAnswers];
    console.log(arry, "after");
    if (arry.includes(key)) {
      arry.splice(arry.indexOf(key), 1);
    }
    console.log(arry, "before");
    setWrongAnswers(arry);
  }

  function handleSolutionInputs(i, event, key) {
    event.preventDefault();
    // const errorId = [...onErrorId];
    // if (!errorId.includes(key)) {
    //   errorId.push(key);
    // }
    // setOnErrorId(errorId);
    // setIsInputActive(false);
    // if (wrongAnswers.length > 0) {
    //   const wrong = wrongAnswers;
    //   if (wrong[i] === key) {
    //     wrong.pop();
    //     setWrongAnswers(wrong);
    //   }
    // }
    // console.log(i)
    // console.log(key)
    removeWrongAnsKeys(key);
    const values = [...solutionAnswer];
    values[i]._id = key;
    values[i].answer = event.target.value;
    // console.log(values);
    setSolutionAnswer(values);
    setIsOnChangeRun(true);
  }

  function UpdateMailToLinks() {
    let allA = document.getElementsByTagName("A");
    for (let i = 0, len = allA.length; i < len; i++) {
      console.log("mailto: llink for loop", allA[i]);
      if (allA[i].href.includes("mailto:")) {
        allA[i].className = "style--for--mailto--location";
      }
    }
  }

  const handleClosePopover = () => {
    setTooglePopover(false);
    // window.location.reload();
    // handleTooltip();
  };
  function handleAddSolutionAnswers(totalAnscount) {
    const values = [];
    totalAnscount.map((res) => {
      values.push({ _id: "", answer: "" });
    });
    setSolutionAnswer(values);
  }

  const handleEmailCollection = async (e) => {
    // setLoad(true);
    // setButtonLoad(true);
    // axios
    //   .post(
    //     `${APIURI}/web-answer/addEmailTosceneHistory/${sceneID}`,
    //     { email: email },
    //     config
    //   )
    //   .then((res) => {
    //     console.log(res);
    //     console.log("No answers");
    //     setButtonLoad(false);
    //     if (nextScene !== 0 && nextScene !== null) {
    //       // console.log("No answers Block IF");
    //       axios
    //         .get(`${APIURI}/web-answer/complete/${sceneID}`, config)
    //         .then((res2) => {
    //           console.log(
    //             "move to next screen called here is the response",
    //             res2
    //           );
    //           window.location.href = `/solution:${gameId}/${nextScene}`;
    //         });
    //       // window.location.href = `/solution:${gameId}/${nextScene}`;
    //     } else {
    //       // console.log("No answers Block Else");
    //       axios
    //         .get(`${APIURI}/web-answer/complete/${sceneID}`, config)
    //         .then((res) => {
    //           console.log(
    //             "move to next screen called here is the response",
    //             res
    //           );
    //           window.location.href = `/congrats:${sceneID}`;
    //         });
    //       // window.location.href = `/congrats:${sceneID}`;
    //     }
    //     setButtonLoad(false);
    //   });
  };

  const renderDesc = (desc) => {
    if (desc.includes("#cameraSection")) {
      return (
        <>
          <div
            className="desc-Text-solution"
            dangerouslySetInnerHTML={createMarkup(
              desc.split("#cameraSection")[0]
            )}
          />
          {/* <div className="desc-Text-solution"> */}
          <CaptureImages color="#000" frames={frames} />
          {/* </div> */}
          {desc.split("#cameraSection")[1] ? (
            <div
              className="desc-Text-solution"
              dangerouslySetInnerHTML={createMarkup(
                `<p>${desc.split("#cameraSection")[1]}`
              )}
            />
          ) : (
            ""
          )}
        </>
      );
    } else {
      return (
        <div
          className="desc-Text-solution"
          dangerouslySetInnerHTML={createMarkup(desc)}
        />
      );
    }
  };

  async function handleTooltip() {
    let elements = document.getElementsByClassName("tooltip");
    let arr = await togglePopoverArr;
    let myFunction = function (e, i, acID) {
      e.preventDefault();
      console.log(e, i, arr[0][acID]);
      setTooglePopover(true);
      setTooglePopoverMessage(arr[0][acID]);
    };

    for (let i = 0; i < elements.length; i++) {
      let actionBtn = elements[i];
      let actionBtnId = actionBtn.id.split("-");
      // console.log(actionBtnId[1]);
      actionBtn.addEventListener(
        "mousedown",
        (e) => myFunction(e, i, actionBtnId[1]),
        false
      );
    }
  }

  {
    /* push notification modal modal  */
  }

  function PushNotificationComponent() {
    return (
      <Dialog
        open={pushNotificationModal}
        onClose={() => {
          setPushNotificationModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="PopOver"
      >
        {/* <DialogTitle id="alert-dialog-title">Message</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <div dangerouslySetInnerHTML={createMarkup(pushNotificationData)} /> */}
            <div>
              <p>{pushNotificationData}</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPushNotificationModal(false);
            }}
            style={{ marginBottom: "2.2rem" }}
            className="dialog-btn-dismiss"
            autoFocus
            variant="contained"
          >
            Okay
          </Button>
          {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
        </DialogActions>
      </Dialog>
    );
  }

  function ContentTypeComponent(data) {
    // const data = props.content;
    if (data.key === "Text") {
      let desc = data.value;
      console.log("Scene Details", sceneDetails);
      // sceneDetails[0].inputFields.map((te) => {
      //   for (const [key, value] of Object.entries(te)) {
      //     // console.log(key, value);
      //     desc = desc.replaceAll("#" + key, value);
      //   }
      // });
      sceneDetails[0].scene_popup.map((te, i) => {
        if (te.icon) {
          desc = desc.replaceAll(
            te.identifier,
            `<span class="tooltip" id='tooltip-${i}'><img src="${te.icon}" alt="PopupIcon" /><span>${te.title}</span></span>`
          );
        } else {
          desc = desc.replaceAll(
            te.identifier,
            `<span class="tooltip" id='tooltip-${i}'><img src ="/assets/PopupIcon.svg" alt="PopupIcon" /><span>${te.title}</span></span>`
          );
        }
        handleTooltip();
      });
      return renderDesc(desc);
    } else if (data.key === "Audio") {
      let yyy = document.getElementById(`audio${data._id}`);
      if (yyy !== null && yyy.currentTime) {
        // console.log("current time",yyy.currentTime)
        window.audioState[data._id] = yyy.currentTime;
        // console.log(window.audioState[data._id])
      }
      return (
        // <audio
        //   style={{ width: "100%", marginBottom: "1em" }}
        //   controls
        //   controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
        //   src={data.value}
        // />
        <div className="solution-media">
          {/* {console.log(playing)} */}
          <Audio content={data.value} id={data._id} />
          {/* <AudioPlayer
          aria-hidden="false"
    autoPlay
    src={data.value}
    onPlay={e => console.log("onPlay")}
    // other props here
  /> */}
        </div>
      );
    } else if (data.key === "Image") {
      return (
        <img
          className="solution-media"
          style={{
            width: "100%",
            height: "100%",
          }}
          src={data.value}
          alt="SceneFeaturedImage"
        />
      );
    } else {
      return (
        <div className="solution-media">
          <Video
            style={{
              width: "100%",
              height: "100%",
              marginBottom: "1em",
            }}
            content={data.value}
          />
        </div>
      );
    }
  }

  const loadPod = async () => {
    setActionButtonLoad(true);
    setLoad(true);
    await axios
      .get(`${APIURI}/web-pods/getPod/${game_id.gameID}`, config)
      .then((res) => {
        console.log(res, "554");
        if (game_id && game_id.gameID) {
          socketNotification.emit("joinRoom", `${game_id.gameID}`);
        } else {
          console.error("game_id or game_id.gameID is not properly defined.");
        }
        let currentDate = Date.now();
        let podExpiryDate = new Date(res.data.podDetails.toDate).getTime();
        if (currentDate > podExpiryDate) {
          window.location.href = "/podExpire";
        }
        setPodDetails(res.data.podDetails);
        if (res.data.teamPageDescription) {
          setTeamPageDesc(res.data.teamPageDescription);
        }
        if (res.data.createTeamDescription) {
          setCreateJoinPageDesc(res.data.createTeamDescription);
        }
        setPodStatus(res.data.podStatus);
        setFrames(res.data.podDetails.Frames);
        setIsFirstSceneAvailable(res.data.firstSceneAvailable);
        let arry = [];
        let scene_id;
        let arrPop = togglePopoverArr;

        // if firstSceneAvailable and code is not validated and scene 1 is not completed
        if (
          res.data.firstSceneAvailable &&
          !res.data.accessCodeValidated &&
          !res.data.scene1Completed
        ) {
          axios
            .get(
              `${APIURI}/web-podscene/view/${res.data.scene1[0]._id}`,
              config
            )
            .then((res2) => {
              console.log(res2, "web-scene first");
            });
          scene_id = res.data.scene1[0]._id;
          setSceneType(1);
          setSceneID(res.data.scene1[0]._id);
          arry.push(res.data.scene1[0]);
          setSceneDetails(arry);
          setSceneDescription(res.data.scene1[0].scene_description);
          setSolMSG(res.data.scene1[0].description);
          setEmailCollectionField(res.data.scene1[0].email_collection);
          setHideContinueBtn(res.data.scene1[0].hide_continue_button);
          arrPop.push(
            res.data.scene1[0].scene_popup.map((te) => te.description)
          );
          setTooglePopoverArr(arrPop);
          handleTooltip();
        }

        // if code is not validated
        else if (!res.data.accessCodeValidated) {
          if (res.data.firstSceneAvailable) {
            axios
              .get(
                `${APIURI}/web-podscene/view/${res.data.scene1[0]._id}`,
                config
              )
              .then((res2) => {
                console.log(res2, "web-scene first");
              });
            scene_id = res.data.scene1[0]._id;
            setSceneType(1);
            setSceneID(res.data.scene1[0]._id);
            arry.push(res.data.scene1[0]);
            setSceneDetails(arry);
            setSceneDescription(res.data.scene1[0].scene_description);
            setSolMSG(res.data.scene1[0].description);
            setEmailCollectionField(res.data.scene1[0].email_collection);
            setHideContinueBtn(res.data.scene1[0].hide_continue_button);
            arrPop.push(
              res.data.scene1[0].scene_popup.map((te) => te.description)
            );
            setTooglePopoverArr(arrPop);
            handleTooltip();
            setShowCongratsStory(true);
            setLoad(false);
            window.scrollTo(0, 0);
            console.log("window.scrollTo(0, 0);");
          } else {
            setShowCongratsStory(true);
            setLoad(false);
            window.scrollTo(0, 0);
            console.log("window.scrollTo(0, 0);");
          }
        }

        // if second scene is available and first scene is completed access code is verified and second scene is not completed
        else if (
          res.data.secondSceneAvailable &&
          res.data.accessCodeValidated &&
          !res.data.scene2Completed
        ) {
          axios
            .get(
              `${APIURI}/web-podscene/view/${res.data.scene2[0]._id}`,
              config
            )
            .then((res2) => {
              console.log(res2, "web-scene second");
            });
          scene_id = res.data.scene2[0]._id;
          arry.push(res.data.scene2[0]);
          setSceneType(2);
          setSceneID(res.data.scene2[0]._id);
          setSceneDetails(arry);
          setSceneDescription(res.data.scene2[0].scene_description);
          setSolMSG(res.data.scene2[0].description);
          setEmailCollectionField(res.data.scene2[0].email_collection);
          setHideContinueBtn(res.data.scene2[0].hide_continue_button);
          arrPop.push(
            res.data.scene2[0].scene_popup.map((te) => te.description)
          );
          setTooglePopoverArr(arrPop);
          handleTooltip();
        }

        // if there is scene_id is present answer things done
        if (scene_id) {
          axios
            .get(`${APIURI}/web-podanswer/all-answers/${scene_id}`, config)
            .then((res2) => {
              console.log("List of all Answer:", res2.data.message);
              setLoad(false);
              setActionButtonLoad(false);
              setTotalAnswers(res2.data.message);
              const count = res2.data.message.length;
              setansCount(count);
              // for (let i = 1; i < count; i++) {
              handleAddSolutionAnswers(res2.data.message);
              // }
              res2.data.message.forEach((key) => {
                axios
                  .get(
                    `${APIURI}/web-podanswer/wrong-answer/${key._id}`,
                    config
                  )
                  .then((res3) => {
                    console.log(res3.data.message);
                    let arr = [];
                    let errorCountArray = [];
                    arr[`${key._id}`] = res3.data.message;
                    errorCountArray[`${key._id}`] = -1;
                    //  arr.push( res.data.message)

                    //  arr[key._id] = res.data.message;

                    errorMessage.push(arr);
                    errorMessageCount.push(errorCountArray);
                    setarrErrorMessageCount(errorMessageCount);
                    setarrErrorMessage(errorMessage);
                    console.log("Wrong Answer from DB:", errorMessage);
                    console.log("Error Count:", errorMessageCount);
                    // setWrongAnswer(res.data.message);
                    // setLoad(false)
                  });
              });

              console.log("AnswerCount: ", ansCount);
              setTimeout(() => {
                UpdateMailToLinks();
              }, 10);
              setLoad(false);
              window.scrollTo(0, 0);
              console.log("window.scrollTo(0, 0);");
            })
            .catch((err) => {
              setTimeout(() => {
                UpdateMailToLinks();
              }, 10);
              setLoad(false);
              window.scrollTo(0, 0);
              console.log("window.scrollTo(0, 0);");
            });
        }

        // show create join team screen or show start scene screen based on web-teams/listTeams response
        else if (res.data.accessCodeValidated) {
          axios
            .get(
              `${APIURI}/web-teams/listTeams/${res.data.podDetails._id}`,
              config
            )
            .then((res) => {
              console.log(res);
              setIsTeamCreator(res.data.creator);
              if (!res.data.message.length) {
                setIsCreateJoinTeamScreen(true);
                setLoad(false);
              } else {
                axios
                  .get(
                    `${APIURI}/web-teams/getAllTeam/${res.data.message[0]._id}`,
                    config
                  )
                  .then((res2) => {
                    console.log(res2, "res2");
                    setTeamInfo(res2.data);
                    setHasPodStarted(res2.data.data.hasStarted);
                    if (res2.data.data) {
                      axios
                        .get(
                          `${APIURI}/web-pods/teamPodStatus/${res2.data.data._id}`,
                          config
                        )
                        .then((res3) => {
                          console.log(res3);
                          setIsPodStartScreen(true);
                          setLoad(false);
                          window.scrollTo(0, 0);
                          console.log("window.scrollTo(0, 0);");
                        });
                    } else {
                      setIsPodStartScreen(true);
                      setLoad(false);
                      window.scrollTo(0, 0);
                      console.log("window.scrollTo(0, 0);");
                    }
                  });
              }
            });
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        if (err.response.data.message === "Access token is missing") {
          window.location.href = `/auth:pod:${game_id.gameID}/0`;
        } else {
          setWrongQr(true);
          setLoad(false);
        }
      });
  };

  const checkGuesUser = () => {
    axios
      .get(`${APIURI}/web-user/getUser`, config)
      .then((res) => {
        console.log(res);
        if (res.data.userData.name.includes("guestuser")) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = `/auth:pod:${game_id.gameID}/0`;
          }, 300);
        }
        // setIsLoading(false);
      })
      .catch((err) => {
        window.location.href = `/auth:pod:${game_id.gameID}/0`;
      });
  };

  useEffect(() => {
    checkGuesUser();
  }, []);

  useEffect(() => {
    const handlePageShow = (event) => {
      // Check if the event's persisted property is false (back navigation)
      if (event.persisted) {
        // Reload the page after a brief delay
        setTimeout(() => {
          window.location.reload();
        }, 100); // Adjust the delay as needed
      }
    };

    window.addEventListener("pageshow", handlePageShow);

    return () => {
      window.removeEventListener("pageshow", handlePageShow);
    };
  }, []);

  useEffect(() => {
    socketNotification.on("message", (data) => {
      console.log(data);
      setPushNotificationModal(true);
      setPushNotificationData(data.description);
    });
    return () => {
      socketNotification.disconnect();
    };
  }, [socketNotification]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      Object.keys(localStorage).forEach((key) => {
        if (key.includes("PushNotification_")) {
          localStorage.removeItem(key);
        }
      });
      axios
        .get(`${APIURI}/web-pods/allMessages/${game_id.gameID}`, config)
        .then((res2) => {
          if (
            res2.data.data.length &&
            res2.data.data[0].Pod_notifications.length > 0
          ) {
            // console.log(res2.data.data[0].Pod_notifications, "all messages");
            let arry = [];
            res2.data.data[0].Pod_notifications.forEach((resData) => {
              if (resData.displayAt) {
                arry.push(resData);
                localStorage.setItem(
                  `PushNotification_${Math.floor(
                    Date.parse(resData.displayAt) / 1000
                  )}`,
                  resData.description
                );
              }
            });
            // console.log(arry);

            // setPodNotificationsOnTime(arry);
          }
        });
    };

    // Set up the interval to call the API every 10 seconds
    const interval = setInterval(() => {
      fetchData();
    }, 5000); // 10000 milliseconds = 10 seconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const ShowNotificationOnTime = () => {
      // const currentTimeStamp = Date.now();
      // var myDate = new Date(currentTimeStamp);
      // var pstDate = myDate.toLocaleString("en-US", {
      //   timeZone: "America/Los_Angeles",
      // });
      // console.log(Date.parse(pstDate), "pst date");
      // var now = new Date();
      // var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
      // console.log(
      //   "utcasdf",
      //   Math.floor(Date.parse(new Date().toISOString()) / 1000)
      // );
      let utc_date = Math.floor(Date.parse(new Date().toISOString()) / 1000);

      // var pst_now = new Date(pstDate);
      // console.log("pst to utc ", Date.parse(new Date(pstDate).toISOString()));

      let notificationVar = localStorage.getItem(
        `PushNotification_${utc_date}`
      );
      if (notificationVar) {
        setPushNotificationModal(true);
        setPushNotificationData(notificationVar);
      }
    };

    // Call the function every second (1000 milliseconds)
    const intervalId = setInterval(ShowNotificationOnTime, 1000);

    // To stop calling the function, clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      loadPod();
    }, [2000]);
  }, [isSomeThingUpdated]);

  if (load) {
    return <Loader />;
  }

  // pod start screen
  if (isPodStartScreen) {
    return (
      <>
        <PodStartStory
          podDetails={podDetails}
          teamInfo={teamInfo}
          isSomeThingUpdated={handleIsSomethingUpdated}
          podStatus={podStatus}
          isTeamCreator={isTeamCreator}
          hasPodStarted={hasPodStarted}
          teamPageDesc={teamPageDesc}
        />
        <PushNotificationComponent />
      </>
      // <LeaderBoard
      //   podDetails={podDetails}
      //   teamInfo={teamInfo}
      //   isSomeThingUpdated={handleIsSomethingUpdated}
      // />
    );
  }

  // create or join team screen
  if (isCreateJoinTeamScreen) {
    return (
      <>
        <CreateJoinTeam
          podDetails={podDetails}
          isSomeThingUpdated={handleIsSomethingUpdated}
          createJoinPageDesc={createJoinPageDesc}
        />
        <PushNotificationComponent />
      </>
    );
  }

  //show congrats screen
  if (showCongratsStory) {
    return (
      <>
        <CongratsForPods
          sceneDetailsFromPods={sceneDetails}
          isSomeThingUpdated={handleIsSomethingUpdated}
          isFirstSceneAvailable={isFirstSceneAvailable}
          sceneID={sceneID}
          frames={frames}
        />
        <PushNotificationComponent />
      </>
    );
  }

  return (
    <>
      <div className="Solution" style={{ marginBottom: "3em" }}>
        {/* success description  */}
        <div>
          {sceneDetails.map((data, index) => {
            return (
              <div key={index}>
                <div>
                  {data.image ? (
                    <img
                      src={data.image}
                      alt={data.title}
                      style={{
                        width: "100.5%",
                        marginLeft: "-1px",
                        marginTop: "-1px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="header">
                  <h2 className="solutionTitle">{data.title}</h2>
                </div>
                <hr />
                <div className="container solution-container">
                  <div className="media-container">
                    {SceneDescription.map((data) => {
                      if (data.type !== "success_description") {
                        return (
                          <div key={data._id}>
                            {/* {data.key==="Audio"?"":<ContentTypeComponent content={data} />} */}
                            {/* <ContentTypeComponent content={data} /> */}
                            {ContentTypeComponent(data)}
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
                {/* <hr /> */}
              </div>
            );
          })}

          {/* input fields  */}
          <div className="container-sol">
            {sceneDetails.map((data) => (
              <div
                key={data._id}
                className="sol-screen"
                dangerouslySetInnerHTML={createMarkup(solMSG)}
              />
            ))}
            {actionButtonLoad ? (
              ""
            ) : (
              <>
                {emailCollectionField ? (
                  <div className="container-code">
                    <div className="sol-enter">
                      <label>Email</label>
                      <input
                        type="text"
                        Value={email}
                        placeholder="Enter Email"
                        onChange={(e) => setEmail(e.target.value)}
                        // onFocus={(e) => handleFocus(index, e, key._id)}
                      />
                      <p />
                    </div>
                    {buttonLoad ? (
                      <Button
                        className="submit--btn disabled"
                        variant="contained"
                        disabled={true}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        className="submit--btn"
                        variant="contained"
                        onClick={(e) => {
                          handleEmailCollection(e);
                        }}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                ) : (
                  <div className="container-code">
                    {/* <TextField  fullWidth id="outlined-basic" label="Submit Answer" variant="outlined" placeholder="TYPE HERE" onChange={(e) => setAnswer(e.target.value)}/> <br /> */}
                    {totalAnswers.map((key, index) => {
                      // console.log("Feom JSX:", index);
                      return (
                        <div className="sol-enter" key={key._id}>
                          <label>{key.label}</label>
                          <input
                            type="text"
                            Value={
                              solutionAnswer[index] &&
                              solutionAnswer[index].answer
                            }
                            placeholder={key.placeholder || "TYPE HERE"}
                            onChange={(e) =>
                              handleSolutionInputs(index, e, key._id)
                            }
                            onFocus={(e) => handleFocus(index, e, key._id)}
                          />
                          {wrongAnswers.includes(key._id) ? (
                            <>
                              {/* {console.log("key._id",key._id)} */}
                              {buttonLoad ? (
                                <p />
                              ) : solutionAnswer[index].answer === "" ? (
                                <p />
                              ) : (
                                <>
                                  <img
                                    className="wrng-icon"
                                    src="/assets/Wrong.svg"
                                    alt="WrongAnswer"
                                    onClick={(e) => {
                                      handleRedCrossClick(index, e, key._id);
                                    }}
                                  />
                                  {isOnChangeRun ? (
                                    <p />
                                  ) : (
                                    <p className="wrong-ans">
                                      {handleWrongAnswerMessage(key._id)}
                                    </p>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <p />
                          )}
                        </div>
                      );
                    })}
                    {totalAnswers.length > 0 ? (
                      <Button
                        className="submit--btn"
                        variant="contained"
                        style={{ opacity: `${buttonLoad ? "0.6" : "1"}` }}
                        onClick={(e) => {
                          handleAnswerValidate(e);
                        }}
                        disabled={buttonLoad}
                      >
                        SUBMIT
                      </Button>
                    ) : hideContinueBtn ? (
                      ""
                    ) : (
                      <Button
                        className="submit--btn"
                        variant="contained"
                        onClick={(e) => {
                          handleAnswerValidateContinue(e);
                        }}
                      >
                        CONTINUE
                      </Button>
                    )}
                  </div>
                )}
              </>
            )}

            {/* {(isAnswerWrong)? <div className="wrong-ans">
                          Wrong Answer - {wronganswer.map(v => <> {v} </>)}
                      </div>: <></> } */}
          </div>
        </div>
      </div>

      <LogoutComponent />
      <PushNotificationComponent />

      {/* popup modal  */}
      <Dialog
        open={togglePopover}
        onClose={handleClosePopover}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="PopOver"
      >
        {/* <DialogTitle id="alert-dialog-title">Message</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div dangerouslySetInnerHTML={createMarkup(togglePopoverMessage)} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosePopover}
            style={{ marginBottom: "2.2rem" }}
            className="dialog-btn-dismiss"
            autoFocus
            variant="contained"
          >
            Okay
          </Button>
          {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
        </DialogActions>
      </Dialog>

      {/* access code modal  */}
      <Dialog
        open={isAccessCodeRequired}
        // onClose={() => setIsAccessCodeRequired(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="access--code--modal"
      >
        <DialogTitle id="alert-dialog-title" className="access--code--required">
          {"Access Code Required"}
        </DialogTitle>
        <DialogContent>
          <div className="Location-Footer-Content">
            <div className="loc-enter">
              <label className="access--code--required--label">
                Enter Access Code
              </label>
              <input
                type="text"
                defaultValue={accessCode}
                placeholder="TYPE HERE"
                onChange={(e) => {
                  setAccessCode(e.target.value);
                  setIncorrectCodeError("");
                }}
                style={{ marginBottom: "10px" }}
              />
            </div>
            <p style={{ color: "#dc3545", margin: "0", fontWeight: "700" }}>
              {incorrectCodeError ? incorrectCodeError : ""}
            </p>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: "16px 24px" }}>
          <Button
            className="dialog-btn-dismiss"
            autoFocus
            variant="contained"
            style={{ width: "100%", opacity: `${loadBtn ? "0.6" : "1"}` }}
            disabled={loadBtn}
            onClick={() => handleValidateCode()}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* wrong qr code  */}
      <Dialog
        open={wrongQr}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"INVALID LINK"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="dialog-desc"
          >
            If you believe you have received this message in error, please
            contact{" "}
            <a href="mailto:support@escapely.com">support@escapely.com</a>.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "2em" }}>
          <Button className="dialog-btn-dismiss" autoFocus variant="contained">
            DISMISS
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withRouter(PodStory);
