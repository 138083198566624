import { Button } from "@material-ui/core";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { APIURI } from "../config";
import { useHistory } from "react-router";

const ChangeNameComponent = ({ name, uploadImage }) => {
  const [newName, setNewName] = useState(name);
  const [errorMessage, setErrorMessage] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const history = useHistory();
  const accessToken = localStorage.getItem("accesstoken");

  let config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const randomName = `input_${Math.random()}`;

  const changeName = () => {
    setBtnLoading(true);
    setErrorMessage("");
    if (uploadImage) {
      const formData = new FormData();
      formData.append("", uploadImage);
      axios
        .post(`${APIURI}/web-user/uploadProfilePicture`, formData, config)
        .then((res) => {
          console.log(res);
          localStorage.setItem("Updated_Profile_Pic", res.data.data);
          axios
            .post(`${APIURI}/web-user/changeName`, { name: newName }, config)
            .then((res2) => {
              console.log(res2);
              setBtnLoading(false);
              // history.push("/profile");
              window.location.href = "/profile";
            })
            .catch((err) => {
              console.log(err.response);
              setBtnLoading(false);
              setErrorMessage(err.response.data.message);
            });
        })
        .catch((err) => {
          console.log(err);
          setBtnLoading(false);
          setErrorMessage("Unable to update image");
        });
    } else {
      axios
        .post(`${APIURI}/web-user/changeName`, { name: newName }, config)
        .then((res) => {
          console.log(res);
          setBtnLoading(false);
          // history.push("/profile");
          window.location.href = "/profile";
        })
        .catch((err) => {
          console.log(err.response);
          setBtnLoading(false);
          setErrorMessage(err.response.data.message);
        });
    }
  };

  return (
    <div className="Auth-login edit-screeens">
      {/* <div className="Auth-header">
        <div className="container">
          <img
            // src="/assets/Escapely@3x.png"
            src="/assets/escapely3Xyellow.png"
            alt="Logo"
            style={{ width: "100%" }}
          />
        </div>
        <p>Change Password</p>
      </div> */}
      <div className="Auth-container">
        <input
          type="text"
          className="login-input"
          placeholder="NEW NAME"
          autoComplete="new-password"
          name={randomName}
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />
        <label>New Name</label>
        <br />
        {/* <p className="wrong-ans">{errorMessage}</p> */}
        <Button
          className="signin"
          variant="contained"
          onClick={changeName}
          disabled={btnLoading || !newName}
          style={{ opacity: `${btnLoading || !newName ? "0.8" : "1"}` }}
        >
          Change Name
        </Button>
      </div>
    </div>
  );
};

export default ChangeNameComponent;
