/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { Context } from "../../store/Store";

function useAudioPlayer(props) {
  const [duration, setDuration] = useState();
  const [curTime, setCurTime] = useState();
  // const [curTime, setCurTime] = useState(Context);
  const [playing, setPlaying] = useState(false);
  // const [playing, setPlaying] = useContext(Context);
  const [clickedTime, setClickedTime] = useState();

  useEffect(() => {
    const audio = document.getElementById(`audio${props}`);
    // state setters wrappers
    const setAudioData = () => {
      setDuration(audio.duration);
      // console.log("setting current time as ", audio.currentTime)
      setCurTime(audio.currentTime);
    }
    // calculateCurrentTime =()=>{
    //   {window.audioState[props.id]?`${props.content}#t=${window.audioState[props.id]}`:`${props.content}`}
    // }

    const setAudioTime = () => setCurTime(audio.currentTime);

    const setAudioEnd = () => setPlaying(false);

    // DOM listeners: update React state on DOM events
    audio.addEventListener("loadeddata", setAudioData);

    audio.addEventListener("timeupdate", setAudioTime);
    
    audio.addEventListener("ended", setAudioEnd);

    // React state listeners: update DOM on React state changes
    playing ? audio.play() : audio.pause();

    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    } 

    // effect cleanup
    return () => {
      audio.removeEventListener("loadeddata", setAudioData);
      audio.removeEventListener("timeupdate", setAudioTime);
    }
  });

  return {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime
  }
}

export default useAudioPlayer;