import { ThreeCircles } from "react-loader-spinner";
const Loader = () => (
     <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // marginTop: "50%",
          // paddingBottom:"170%",
          backgroundColor:"white",
          height:"100vh"
        }}
      >
        <ThreeCircles
            outerCircleColor="#FFFB00"
            middleCircleColor="#FFFB00"
            innerCircleColor="#000" 
            height={100} 
            width={100} 
        />{" "}
      </div>
);
export default Loader;