import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import { APIURI } from "../config";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { TermsPopUp } from "../popups/TermsPopUp";
import TermsChecked from "../context/TermsChecked";
import IsTermsPopUp from "../context/IsTermsPopUp";

function ForceForgotPassword(props) {
  let history = useHistory();
  const gameId = props.match.params.gameID;
  const sceneId = props.match.params.sceneId;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [fullname, setFullname] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useContext(TermsChecked);
  const [isPopupOpen, setIsPopupOpen] = useContext(IsTermsPopUp);
  let currentUrl = window.location.href.split(":").pop();
  console.log(currentUrl);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const handleSave = (event) => {
    event.preventDefault();
    setLoading(true);
    const data = {
      email: email.toLowerCase(),
    };
    // if (isTermsChecked) {
    if (email) {
      axios
        .patch(`${APIURI}/users/forgot`, data)
        .then((res) => {
          console.log(res);
          if (res.data.message.includes("Customer not found with this email")) {
            setErrorMessage(
              "Looks like we don't have your email in our system! Click below to create an account."
            );
            setLoading(false);
          } else {
            if (
              res.data.message.includes(
                "Password reset link has been sent to your registered email"
              )
            ) {
              setErrorMessage(
                "Check your email for your password reset link. After you reset your password, return to this app to login."
              );
            } else {
              setErrorMessage(res.data.message + ".");
            }
            setLoading(false);
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
    } else {
      setErrorMessage("Please enter a valid email.");
      setLoading(false);
    }
  };
  // else {
  //   setErrorMessage("Please accept Terms & Conditions.");
  //   setLoading(false);
  // }
  //   };
  return (
    <div className="Auth-login">
      <div className="Auth-header">
        <button className="backbutton-signup" onClick={(e) => history.goBack()}>
          <ArrowBackIcon />
        </button>
        <div className="container">
          <img
            src="/assets/Escapely@3x.png"
            alt="Logo"
            style={{ width: "100%" }}
          />
        </div>

        {/* <p>Enter email to reset password</p> */}
        <p>It's time to reset your password. Enter email to continue.</p>
      </div>
      <div className="Auth-container">
        <input
          className="login-input login-email"
          placeholder="ENTER EMAIL"
          autocomplete="off"
          onChange={(e) => {
            setEmail(e.target.value);
            setErrorMessage("");
          }}
        />
        <label className="mb-15">EMAIL</label>
        <br />
        <p className="wrong-ans">{errorMessage}</p>
        {/* {isPopupOpen ? <TermsPopUp type="signup" /> : ""}
          <div style={{ display: "flex", alignItems: "center" }}>
            <FormControlLabel
              style={{ marginRight: "5px" }}
              className="terms-condition-label"
              control={
                <Checkbox
                  className="terms-checkbox"
                  onClick={() => {
                    setIsPopupOpen(true);
                  }}
                  name="checkedB"
                  checked={isTermsChecked}
                  color="dark"
                />
              }
              label=<p className="terms-condition">I accept the</p>
            />
            <span
              className="terms-condition"
              onClick={() => {
                setIsPopupOpen(true);
              }}
              style={{ color: "#000", textDecoration: "underline" }}
            >
              Terms & Conditions
            </span>
          </div> */}
        {/* <FormControlLabel control={ <Checkbox checked={true} name="checkedB" color="primary" />} label="I agree to the terms and conditions"/> <br /> */}
        {loading ? (
          <Button
            className="signup"
            style={{ background: "#333200" }}
            variant="contained"
            disabled={true}
          >
            Confirm
          </Button>
        ) : (
          <Button className="signup" variant="contained" onClick={handleSave}>
            Confirm
          </Button>
        )}
      </div>
      <div className="forgot-pass">
        <div style={{ width: "48%", borderRight: "1.8px solid #000" }}>
          {gameId === undefined ? (
            <a href="/signup">Create Account</a>
          ) : (
            <a href={`/signup:${gameId}/${sceneId}`}>Create Account</a>
          )}
        </div>
        <div style={{ width: "51%" }}>
          {gameId === undefined ? (
            <a href="/auth">Sign in</a>
          ) : (
            <a href={`/auth:${gameId}/${sceneId}`}>Sign in</a>
          )}
        </div>
      </div>
      <br />
      <br /> <br />
      <br />
      <br /> <br /> <br />
    </div>
  );
}
export default withRouter(ForceForgotPassword);
