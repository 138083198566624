import React,{useEffect,useState} from "react";

import Song from "./Song";
import Play from "./Play";
import Pause from "./Pause";
import Bar from "./Bar";

import useAudioPlayer from './useAudioPlayer';
import { Timeline } from "@material-ui/icons";

function Audio(props) {
  const [dataFromLocal, setDataFromLocal] = useState();
  const { curTime, duration, playing, setPlaying, setClickedTime ,setCurTime} = useAudioPlayer(props.id);
  const pausefunction=()=>{
    setPlaying(false)
    window.isAudioPlaying=false
  }
const playfunction=()=>{
  setPlaying(true)
  window.isAudioPlaying=true
  // console.log("propId",window.audioState[props.id])
}
// var timeLine= curTime
//   if(curTime==undefined){
//     // console.log("undefined undefined undefined undefined undefined")
//     timeLine=window.audioState[props.id]
//     // console.log("propId",window.audioState[props.id])
//   }
//   else{
//     timeLine<window.audioState[props.id]?timeLine=window.audioState[props.id]:""
//   }
  // useEffect(() => {
  //  const datafromLocal= window.localStorage.getItem(`${props.id}`,window.audioState)
  //  setDataFromLocal(JSON.parse(datafromLocal)) 
  // });
  return (
    <div className="player">
      <audio id={`audio${props.id}`} loop={false}>
        <source src={window.audioState[props.id]?`${props.content}#t=${window.audioState[props.id]}`:`${props.content}`} />
        Your browser does not support the <code>audio</code> element.
      </audio>
      {/* <Song songName="Instant Crush" songArtist="Daft Punk ft. Julian Casablancas" /> */}
      <div className="controls">
        {playing ? 
          <Pause handleClick={() => pausefunction()} /> :
          <Play handleClick={() => playfunction()} />
        }
         {/* { console.log("values Received by befor updatation bar", timeLine)} */}
        {/* {
          curTime<window.audioState[props.id]?setCurTime(window.audioState[props.id]):""
        } */}
        {/* {console.log("values Received by after updatation bar", timeLine)} */}
        <Bar curTime={curTime} duration={duration} onTimeUpdate={(time) => setClickedTime(time)} />
      </div>
    </div>
  );
}

export default Audio;
