import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckIcon from "@material-ui/icons/Check";
import axios from "axios";
import { APIURI } from "../config";
import copy from "copy-to-clipboard";
import createMarkup from "../utils/CreateMarkup";

const CreateJoinTeam = ({
  podDetails,
  isSomeThingUpdated,
  createJoinPageDesc,
}) => {
  const [joinATeamModal, setJoinATeamModal] = useState(false);
  const [codeForJoin, setCodeForJoin] = useState("");
  const [createATeamModal, setCreateATeamModal] = useState(false);
  const [TeamName, setTeamName] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [togglePopoverArr, setTooglePopoverArr] = useState([]);
  const [togglePopover, setTooglePopover] = useState(false);
  const [togglePopoverMessage, setTooglePopoverMessage] = useState([]);
  const [errorModal, setErrorModal] = useState(false);
  const [isCreateTeamORJoinTeam, setIsCreateTeamORJoinTeam] = useState("");
  const accessToken = localStorage.getItem("accesstoken");

  let config;
  config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  async function handleTooltip() {
    let elements = document.getElementsByClassName("tooltip");
    let arr = await togglePopoverArr;
    let myFunction = function (e, i, acID) {
      e.preventDefault();
      console.log(arr);
      console.log(e, i, arr[0][acID]);
      setTooglePopover(true);
      setTooglePopoverMessage(arr[0][acID]);
    };

    for (let i = 0; i < elements.length; i++) {
      let actionBtn = elements[i];
      let actionBtnId = actionBtn.id.split("-");
      // console.log(actionBtnId[1]);
      actionBtn.addEventListener(
        "mousedown",
        (e) => myFunction(e, i, actionBtnId[1]),
        false
      );
    }
  }

  const handleRemoveFromTeam = () => {
    axios
      .get(`${APIURI}/web-teams/removeFromOtherTeams`, config)
      .then((res) => {
        console.log(res);
        setBtnLoad(false);
        setErrorModal(true);
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage("Something Went Wrong!");
        setBtnLoad(false);
      });
  };

  const handleCreateTeam = () => {
    setIsCreateTeamORJoinTeam("createTeam");
    setBtnLoad(true);
    axios
      .post(
        `${APIURI}/web-teams/createTeam/${podDetails.game_id}`,
        { pod_id: podDetails._id, teamName: TeamName },
        config
      )
      .then((res) => {
        console.log(res);
        isSomeThingUpdated();
        setBtnLoad(false);
      })
      .catch((err) => {
        console.log(err.response);
        if (
          err.response &&
          err.response.data.message ===
            "Team Name or Team Code already exists for this pod id"
        ) {
          setBtnLoad(false);
          setErrorMessage("Team name already in use. Please choose another.");
        } else if (
          err.response &&
          err.response.data.message ===
            "User is already a part of another active pod"
        ) {
          handleRemoveFromTeam();
        } else {
          setBtnLoad(false);
          setErrorMessage(err.response.data.message);
        }
      });
  };

  const handleJoinTeam = () => {
    setIsCreateTeamORJoinTeam("joinTeam");
    setBtnLoad(true);
    axios
      .post(
        `${APIURI}/web-teams/joinTeam`,
        { pod_id: podDetails._id, teamCode: codeForJoin },
        config
      )
      .then((res) => {
        console.log(res);
        isSomeThingUpdated();
        setBtnLoad(false);
      })
      .catch((err) => {
        console.log(err.response);

        if (err.response.data.message === "Team code does not exist") {
          setErrorMessage("Invalid code. Codes are case sensitive.");
          setBtnLoad(false);
        } else if (
          err.response &&
          err.response.data.message ===
            "User is already a part of another active pod"
        ) {
          handleRemoveFromTeam();
        } else {
          setErrorMessage(err.response.data.message);
          setBtnLoad(false);
        }
      });
  };

  const handleClosePopover = () => {
    setTooglePopover(false);
    // window.location.reload();
    // handleTooltip();
  };

  const handlePopUps = (data) => {
    let desc = data;
    if (podDetails && podDetails.Pod_popup) {
      let arrPop = togglePopoverArr;
      arrPop.push(podDetails.Pod_popup.map((te) => te.description));
      console.log(arrPop);
      setTooglePopoverArr(arrPop);
    }
    if (podDetails && podDetails.Pod_popup) {
      podDetails.Pod_popup.map((te, i) => {
        if (te.icon) {
          desc = desc.replaceAll(
            te.identifier,
            `<span class="tooltip" id='tooltip-${i}'><img src="${te.icon}" alt="PopupIcon" /><span>${te.title}</span></span>`
          );
        } else {
          desc = desc.replaceAll(
            te.identifier,
            `<span class="tooltip" id='tooltip-${i}'><img src ="/assets/PopupIcon.svg" alt="PopupIcon" /><span>${te.title}</span></span>`
          );
        }
        handleTooltip();
      });
    }
    return (
      <div
        // className="sol-screen"
        className="desc-Text-solution"
        dangerouslySetInnerHTML={createMarkup(desc)}
      />
    );
  };

  const popupMemo = useMemo(() => handlePopUps(createJoinPageDesc), []);

  // useEffect(() => {
  //   if (podDetails && podDetails.Pod_popup) {
  //     let arrPop = togglePopoverArr;
  //     arrPop.push(podDetails.Pod_popup.map((te) => te.description));
  //     console.log(arrPop)
  //     setTooglePopoverArr(arrPop);
  //     handleTooltip();
  //   }
  // }, []);

  const handleOkayBtn = (e) => {
    e.preventDefault();
    setErrorModal(false);
    if (isCreateTeamORJoinTeam === "createTeam") {
      handleCreateTeam();
    } else {
      handleJoinTeam();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="Solution">
        <div className="header">
          <h2 className="solutionTitle">{podDetails && podDetails.title}</h2>
        </div>
        <hr />
        <div className="container-sol">
          {popupMemo}
          <div className="container-code">
            <Button
              className="submit--btn"
              variant="contained"
              onClick={() => {
                setCreateATeamModal(true);
              }}
            >
              CREATE A TEAM
            </Button>
            <Button
              className="submit--btn"
              variant="contained"
              onClick={() => {
                setJoinATeamModal(true);
              }}
            >
              JOIN A TEAM
            </Button>
          </div>
        </div>
      </div>

      {/* join a team modal  */}
      <Dialog
        open={joinATeamModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="access--code--modal create--join--team--modals"
      >
        <DialogTitle id="alert-dialog-title" className="access--code--required">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{"JOIN A TEAM"}</span>
            <Button
              className="image--capture--modal--close--btn"
              autoFocus
              variant="contained"
              onClick={() => {
                setCodeForJoin("");
                setErrorMessage("");
                setJoinATeamModal(false);
              }}
            ></Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="Location-Footer-Content">
            <div className="loc-enter">
              <label className="access--code--required--label">
                ENTER CODE
              </label>
              <input
                type="text"
                defaultValue={codeForJoin}
                placeholder="TYPE HERE"
                autoCorrect="off" // Turn off autocorrect
                autoCapitalize="none" // Turn off autocapitalization
                onChange={(e) => {
                  setCodeForJoin(e.target.value);
                  setErrorMessage("");
                }}
                style={{ marginBottom: "10px" }}
              />
            </div>
            <p style={{ color: "#dc3545", margin: "0", fontWeight: "700" }}>
              {errorMessage ? errorMessage : ""}
            </p>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: "16px 24px" }}>
          <Button
            className="dialog-btn-dismiss"
            autoFocus
            variant="contained"
            style={{
              width: "100%",
              opacity: `${codeForJoin.length < 4 || btnLoad ? "0.6" : "1"}`,
            }}
            disabled={codeForJoin.length < 4 || btnLoad}
            onClick={() => {
              handleJoinTeam();
            }}
          >
            CONTINUE
          </Button>
        </DialogActions>
      </Dialog>

      {/* create a team modal  */}
      <Dialog
        open={createATeamModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="access--code--modal create--join--team--modals"
      >
        <DialogTitle id="alert-dialog-title" className="access--code--required">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{"CREATE A TEAM"}</span>
            <Button
              className="image--capture--modal--close--btn"
              autoFocus
              variant="contained"
              onClick={() => {
                setCodeForJoin("");
                setTeamName("");
                setErrorMessage("");
                setCreateATeamModal(false);
              }}
            ></Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="Location-Footer-Content">
            <div className="loc-enter">
              <label className="access--code--required--label">TEAM NAME</label>
              <input
                type="text"
                defaultValue={TeamName}
                autoCorrect="off" // Turn off autocorrect
                autoCapitalize="none" // Turn off autocapitalization
                placeholder="TYPE HERE"
                onChange={(e) => {
                  setTeamName(e.target.value);
                  setErrorMessage("");
                }}
                style={{ marginBottom: "10px" }}
              />
            </div>
            {/* <div className="loc-enter margin--top--1rem">
              <label className="access--code--required--label">
                ENTER CODE
              </label>
              <input
                type="text"
                defaultValue={codeForJoin}
                placeholder="TYPE HERE"
                onChange={(e) => {
                  setCodeForJoin(e.target.value);
                  setErrorMessage("");
                }}
                style={{ marginBottom: "10px" }}
              />
              {isCopied ? (
                <CheckIcon
                  style={{ position: "absolute", top: "18px", right: "10px" }}
                />
              ) : (
                <FileCopyIcon
                  style={{ position: "absolute", top: "18px", right: "10px" }}
                  onClick={() => {
                    setIsCopied(true);
                    copy(codeForJoin);
                    setTimeout(() => {
                      setIsCopied(false);
                    }, 4000);
                  }}
                />
              )}
            </div> */}
            <p style={{ color: "#dc3545", margin: "0", fontWeight: "700" }}>
              {errorMessage ? errorMessage : ""}
              {TeamName.length > 30
                ? "Team name must not exceed 30 characters."
                : ""}
            </p>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: "16px 24px" }}>
          <Button
            className="dialog-btn-dismiss"
            autoFocus
            variant="contained"
            style={{
              width: "100%",
              opacity: `${btnLoad || TeamName.length > 30 ? "0.6" : "1"}`,
            }}
            disabled={btnLoad || TeamName.length > 30}
            onClick={() => {
              handleCreateTeam();
            }}
          >
            CONTINUE
          </Button>
        </DialogActions>
      </Dialog>

      {/* popup modal  */}
      <Dialog
        open={togglePopover}
        onClose={handleClosePopover}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="PopOver"
      >
        {/* <DialogTitle id="alert-dialog-title">Message</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div dangerouslySetInnerHTML={createMarkup(togglePopoverMessage)} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosePopover}
            style={{ marginBottom: "2.2rem" }}
            className="dialog-btn-dismiss"
            autoFocus
            variant="contained"
          >
            Okay
          </Button>
          {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
        </DialogActions>
      </Dialog>

      {/* error modal  */}
      <Dialog
        open={errorModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Did you skip to scan the QR Code?"}
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* Oops! looks like you’ve skipped the previous Game QR Code, Please
            scan the Game QR Code to continue.! */}
            This account was already part of another event. You have been
            removed from that event but can rejoin at any time via that event's
            link.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "16px 24px" }}>
          <Button
            onClick={(e) => {
              handleOkayBtn(e);
            }}
            className="dialog-btn-dismiss"
            autoFocus
            variant="contained"
          >
            Okay
          </Button>
          {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateJoinTeam;
