// export const APIURI = 'http://ec2-18-217-152-232.us-east-2.compute.amazonaws.com:5000';
// export const APIURI = 'http://54.241.34.9:3000';
// export const APIURI = "https://es-api-prod.immodesta.com";  //prod old

export const APIURI = "https://api-escapley.immodesta.com"; //dev

// export const APIURI = "https://api.escapelygames.com";  //prod
// export const SOCKET_URI = "wss://ws-escapely-prod.immodesta.com";  //prod

export const SOCKET_URI = "wss://ws-escapley.immodesta.com"; //dev
