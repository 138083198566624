import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CountdownTimer from "../components/CountdownTimer";
import axios from "axios";
import { APIURI, SOCKET_URI } from "../config";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../utils/Loader";
import io from "socket.io-client";
const socketNotification = io(`${SOCKET_URI}/notification`, {
  reconnection: true, // Enable automatic reconnection
  reconnectionAttempts: 10, // Number of reconnection attempts
  reconnectionDelay: 2000, // Delay between reconnection attempts (in milliseconds)
});

const LeaderBoard = () => {
  const [leaderBoardData, setLeaderBoardData] = useState([]);
  const [leaderBoardTitle, setLeaderBoardTitle] = useState("");
  const [podNotificationsOnTime, setPodNotificationsOnTime] = useState([]);
  const [pushNotificationModal, setPushNotificationModal] = useState(false);
  const [pushNotificationData, setPushNotificationData] = useState("");
  const [podId2, setPodId2] = useState("");
  const [load, setLoad] = useState(true);
  const podId = useParams();
  console.log(podId.podId);

  function convertSecondsToDHMS(seconds) {
    const oneMinute = 60;
    const oneHour = 60 * oneMinute;
    const oneDay = 24 * oneHour;

    const days = Math.floor(seconds / oneDay);
    seconds %= oneDay;

    const hours = Math.floor(seconds / oneHour);
    seconds %= oneHour;

    const minutes = Math.floor(seconds / oneMinute);
    seconds %= oneMinute;
    console.log(days, hours, minutes, seconds);

    if (days) {
      return (
        <span>
          {days.toString()}:{hours.toString().padStart(2, "0")}:
          {minutes.toString().padStart(2, "0")}:
          {Math.floor(Number(seconds)).toString().padStart(2, "0")}
        </span>
      );
    } else if (hours) {
      return (
        <span>
          {hours.toString()}:{minutes.toString().padStart(2, "0")}:
          {Math.floor(Number(seconds)).toString().padStart(2, "0")}
        </span>
      );
    } else if (minutes) {
      return (
        <span>
          {minutes.toString()}:
          {Math.floor(Number(seconds)).toString().padStart(2, "0")}
        </span>
      );
    } else if (seconds) {
      return (
        <span>
          00:{Math.floor(Number(seconds)).toString().padStart(2, "0")}
        </span>
      );
    } else {
      <span></span>;
    }
  }

  useEffect(() => {
    const handlePageShow = (event) => {
      // Check if the event's persisted property is false (back navigation)
      if (event.persisted) {
        // Reload the page after a brief delay
        setTimeout(() => {
          window.location.reload();
        }, 100); // Adjust the delay as needed
      }
    };

    window.addEventListener("pageshow", handlePageShow);

    return () => {
      window.removeEventListener("pageshow", handlePageShow);
    };
  }, []);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      axios
        .get(`${APIURI}/web-pods/leaderboard/${podId.podId}`, config)
        .then((res) => {
          const arry = [];
          res.data.data.forEach((data) => {
            if (data.active) {
              if (data.totalDuration) {
                arry.push(data);
              }
              // else {
              //   const dateCreated = new Date(data.date_created);
              //   const now = new Date();
              //   const differenceInMilliseconds = now - dateCreated;
              //   const secondsDifference = differenceInMilliseconds / 1000;
              //   console.log(secondsDifference);
              //   data.totalDuration = secondsDifference;
              //   arry.push(data);
              // }
            }
          });
          arry.sort((a, b) => a.totalDuration - b.totalDuration);
          console.log(arry, "1348as");
          setLeaderBoardData(arry);
          setLoad(false);
        });
    };

    // Set up the interval to call the API every 10 seconds
    const interval = setInterval(() => {
      fetchData();
    }, 7000); // 10000 milliseconds = 10 seconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    socketNotification.on("message", (data) => {
      console.log(data);
      setPushNotificationModal(true);
      setPushNotificationData(data.description);
    });
    return () => {
      socketNotification.disconnect();
    };
  }, [socketNotification]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      Object.keys(localStorage).forEach((key) => {
        if (key.includes("PushNotification_")) {
          localStorage.removeItem(key);
        }
      });
      axios
        .get(`${APIURI}/web-pods/allMessages/${podId2}`, config)
        .then((res2) => {
          if (
            res2.data.data.length &&
            res2.data.data[0].Pod_notifications.length > 0
          ) {
            console.log(res2.data.data[0].Pod_notifications, "all messages");
            let arry = [];
            res2.data.data[0].Pod_notifications.forEach((resData) => {
              if (resData.displayAt) {
                arry.push(resData);
                localStorage.setItem(
                  `PushNotification_${Math.floor(
                    Date.parse(resData.displayAt) / 1000
                  )}`,
                  resData.description
                );
              }
            });
            console.log(arry);

            // setPodNotificationsOnTime(arry);
          }
        });
    };

    // Set up the interval to call the API every 10 seconds
    const interval = setInterval(() => {
      if (podId2) {
        fetchData();
      }
    }, 5000); // 10000 milliseconds = 10 seconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, [podId2]);

  useEffect(() => {
    const ShowNotificationOnTime = () => {
      // const currentTimeStamp = Date.now();
      // var myDate = new Date(currentTimeStamp);
      // var pstDate = myDate.toLocaleString("en-US", {
      //   timeZone: "America/Los_Angeles",
      // });
      // console.log(Date.parse(pstDate), "pst date");
      // var now = new Date();
      // var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
      // console.log(
      //   "utcasdf",
      //   Math.floor(Date.parse(new Date().toISOString()) / 1000)
      // );
      let utc_date = Math.floor(Date.parse(new Date().toISOString()) / 1000);

      // var pst_now = new Date(pstDate);
      // console.log("pst to utc ", Date.parse(new Date(pstDate).toISOString()));

      let notificationVar = localStorage.getItem(
        `PushNotification_${utc_date}`
      );
      if (notificationVar) {
        setPushNotificationModal(true);
        setPushNotificationData(notificationVar);
      }
    };

    // Call the function every second (1000 milliseconds)
    const intervalId = setInterval(ShowNotificationOnTime, 1000);

    // To stop calling the function, clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const accessToken = localStorage.getItem("accesstoken");
  let config;
  config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  useEffect(() => {
    axios
      .get(`${APIURI}/web-pods/getPod/${podId.podId}`, config)
      .then((res) => {
        console.log(res);
        setLeaderBoardTitle(res.data.podDetails.leaderboardTitle);
        if (res.data.podDetails && res.data.podDetails._id) {
          socketNotification.emit("joinRoom", `${res.data.podDetails._id}`);
          setPodId2(res.data.podDetails._id);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  }, []);

  if (load) {
    return <Loader />;
  }

  return (
    <>
      <div className="leaderboard--main--screen">
        <div className="Solution pod-start-screen">
          <div className="header">
            <h2 className="solutionTitle">
              {leaderBoardTitle}
              <br /> <span style={{ fontSize: "22px" }}> Leaderboard </span>
            </h2>
          </div>
          <hr />
          <div className="trophyImg--div">
            <img src="/assets/Trophy.svg" alt="" srcSet="" />
          </div>
          <div
            className="container-sol"
            style={{
              margin: "1rem 2rem 0 2rem",
              width: "auto",
              paddingBottom: "2rem",
            }}
          >
            <div className="container-code" style={{ width: "auto" }}>
              <table className="leader--board--table">
                {leaderBoardData.map((data, index) => {
                  return (
                    <tr>
                      <td className="td--top" style={{ textAlign: "center" }}>
                        <div
                          className={
                            index + 1 < 4
                              ? "Top--3--leaderboard"
                              : "Normal--leaderboard"
                          }
                        >
                          {index + 1}
                        </div>
                      </td>
                      <td className="td--top">
                        <div style={{ marginLeft: "10px" }}>
                          <span
                            style={{
                              fontSize: "22px",
                              textTransform: "uppercase",
                              color: "#fff",
                            }}
                          >
                            {data.title}
                          </span>{" "}
                          <br />{" "}
                          <span>
                            TOTAL TIME{" "}
                            {data.totalDuration
                              ? convertSecondsToDHMS(data.totalDuration)
                              : ""}
                            <br />
                            {data.penaltyDuration ? (
                              <span>
                                PENALTIES{" "}
                                {data.penaltyDuration &&
                                (data.penaltyDuration / 60)
                                  .toString()
                                  .includes(".")
                                  ? `${Number(
                                      (data.penaltyDuration / 60).toFixed(2)
                                    )} MIN`
                                  : `${data.penaltyDuration / 60} MIN`}
                              </span>
                            ) : (
                              <span>PENALTIES 0 MIN</span>
                            )}
                          </span>
                        </div>
                      </td>
                      {/* <td className="td--top">{index === 0 ? "Win" : ""}</td> */}
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* push nofication  */}
      <Dialog
        open={pushNotificationModal}
        onClose={() => {
          setPushNotificationModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="PopOver"
      >
        {/* <DialogTitle id="alert-dialog-title">Message</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <div dangerouslySetInnerHTML={createMarkup(pushNotificationData)} /> */}
            <div>
              <p>{pushNotificationData}</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPushNotificationModal(false);
            }}
            style={{ marginBottom: "2.2rem" }}
            className="dialog-btn-dismiss"
            autoFocus
            variant="contained"
          >
            Okay
          </Button>
          {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LeaderBoard;
