import React from "react";

export default function DrawerSplashForPods() {
  return (
    <div className="drawersplash">
      <div className="container-img">
        <img
          src="/assets/Lock@3x.png"
          alt=""
          style={{ width: "70px", height: "120px" }}
        />
      </div>
      <div className="container-text">
        <div className="confirmation-screen">
          <h2 style={{ textAlign: "left" }}>ACTIVATE GAME TO PLAY</h2>
          <p>Refer to your email for access code and activation link.</p>
        </div>
      </div>
    </div>
  );
}
