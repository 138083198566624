/* eslint-disable react/jsx-no-target-blank */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";
import { useHistory, withRouter } from "react-router";
import Swal from "sweetalert2";
import { APIURI, SOCKET_URI } from "../config";
import CancelIcon from "@material-ui/icons/Cancel";
import createMarkup from "../utils/CreateMarkup";
import Loader from "../utils/Loader";
import Video from "../utils/Videos/Video";
import Audio from "../utils/Audios/Audio";
import { useEffect } from "react";
import CaptureImages from "./CaptureImages";
import io from "socket.io-client";
import Footer from "./Footer";
const socketNotification = io(`${SOCKET_URI}/notification`, {
  reconnection: true, // Enable automatic reconnection
  reconnectionAttempts: 10, // Number of reconnection attempts
  reconnectionDelay: 2000, // Delay between reconnection attempts (in milliseconds)
});

function CongratsStory(props) {
  let history = useHistory();
  const sceneID = props.match.params.sceneID;
  const [frames, setFrames] = useState([]);
  const [podId, setPodId] = useState("");
  const [podFrames, setPodFrames] = useState([]);
  const [isPodActive, setIsPodActive] = useState(false);
  const [pushNotificationModal, setPushNotificationModal] = useState(false);
  const [pushNotificationData, setPushNotificationData] = useState("");
  const [podNotificationsOnTime, setPodNotificationsOnTime] = useState([]);

  const accessToken = localStorage.getItem("accesstoken");
  const accessCode = localStorage.getItem("accessCode");
  let config;
  if (accessCode) {
    config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        accesscode: accessCode,
      },
    };
  } else {
    config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
  }
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const [open, setOpen] = useState(false);
  const [sceneDetails, setSceneDetails] = useState([]);
  const [SceneDescription, setSceneDescription] = useState([]);
  const [showLeaderBoardBtn, setShowLeaderBoardBtn] = useState(false);

  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0.5);
  const [load, setLoad] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [buttonsElements, setButtonsElements] = useState([]);

  const [togglePopover, setTooglePopover] = useState(false);
  const [togglePopoverMessage, setTooglePopoverMessage] = useState([]);
  const [togglePopoverArr, setTooglePopoverArr] = useState([]);

  // if (document.querySelector("a")) {
  //   const element = document.querySelector("a").href.includes("mailto:");
  //   if (element) {
  //     if (isLast) {
  //       document.querySelector("a").classList.add("style--for--mailto--last");
  //     } else {
  //       document.querySelector("a").classList.add("style--for--mailto");
  //     }
  //     console.log("mailto: element", document.querySelector("a"));
  //   }
  // }

  const renderDesc = (desc) => {
    if (desc.includes("#cameraSection")) {
      return (
        <>
          <div
            className="desc-Text"
            dangerouslySetInnerHTML={createMarkup(
              desc.split("#cameraSection")[0]
            )}
          />
          <div className="desc-Text">
            <CaptureImages
              color={isLast ? "#b2b2b2" : "#000"}
              frames={
                isPodActive
                  ? podFrames.length > 0
                    ? podFrames
                    : frames
                  : frames
              }
            />
          </div>
          {desc.split("#cameraSection")[1] ? (
            <div
              className="desc-Text"
              dangerouslySetInnerHTML={createMarkup(
                `<p>${desc.split("#cameraSection")[1]}`
              )}
            />
          ) : (
            ""
          )}
        </>
      );
    } else {
      return (
        <div
          className="desc-Text"
          dangerouslySetInnerHTML={createMarkup(desc)}
        />
      );
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  async function handleTooltip() {
    let elements = document.getElementsByClassName("tooltip");
    let arr = await togglePopoverArr;
    let myFunction1 = function (e, i, acID) {
      console.log("Handle Tooltip Func");
      e.preventDefault();
      setTooglePopover(true);
      console.log(e, i, arr[0][acID]);
      setTooglePopoverMessage(arr[0][acID]);
    };

    for (let i = 0; i < elements.length; i++) {
      let actionBtn = elements[i];
      let actionBtnId = actionBtn.id.split("-");
      console.log(actionBtnId[1]);
      actionBtn.addEventListener(
        "mousedown",
        (e) => myFunction1(e, i, actionBtnId[1]),
        false
      );
    }
  }
  const submitFeedback = (e) => {
    setLoad(true);
    e.preventDefault();
    const data = {
      scene_id: sceneID,
      stars: rating,
      review: review,
      user_id: "placeholder",
    };
    axios
      .post(`${APIURI}/web-rating/save`, data, config)
      .then((res) => {
        //     Toast.fire({
        //         icon: 'success',
        //         title: 'Thank you for your feedback!'
        //    })
        setOpen(false);
        setLoad(false);
      })
      .catch((err) => {
        //     Toast.fire({
        //         icon: 'error',
        //         title: 'Try again later!'
        //    })
        setLoad(false);
      });
  };
  const StarRatings = {
    size: 40,
    isHalf: true,
    count: 5,
    value: rating,
    onChange: (newValue) => {
      setRating(newValue);
    },
  };
  function UpdateMailToLinks() {
    // if(document.querySelector("a")){
    //   const element = document.querySelector("a").href.includes("mailto:")
    //   if(element){
    //     document.querySelector("a").classList.add("style--for--mailto");
    //     console.log("mailto: element",document.querySelector("a"))
    //     alert(document.querySelector("a"))
    //   }
    //   }
    let allA = document.getElementsByTagName("A");
    // console.log("mailto: llink",allA)
    for (let i = 0, len = allA.length; i < len; i++) {
      console.log("mailto: llink for loop", allA[i]);
      if (allA[i].href.includes("mailto:")) {
        if (isLast) {
          allA[i].className = "style--for--mailto--last";
        } else {
          allA[i].className = "style--for--mailto";
        }
        //  console.log("mailto: llink",allA[i])
      }
    }
    setLoad(false);
  }

  useEffect(() => {
    const handlePageShow = (event) => {
      // Check if the event's persisted property is false (back navigation)
      if (event.persisted) {
        // Reload the page after a brief delay
        setTimeout(() => {
          window.location.reload();
        }, 100); // Adjust the delay as needed
      }
    };

    window.addEventListener("pageshow", handlePageShow);

    return () => {
      window.removeEventListener("pageshow", handlePageShow);
    };
  }, []);

  useEffect(() => {
    socketNotification.on("message", (data) => {
      console.log(data);
      setPushNotificationModal(true);
      setPushNotificationData(data.description);
    });
    return () => {
      socketNotification.disconnect();
    };
  }, [socketNotification]);

  useEffect(() => {
    UpdateMailToLinks();
  }, [load]);
  function ContentTypeComponent(data) {
    // const data = props.content;
    if (data.key === "Text") {
      let desc = data.value;
      console.log("Scene Details", sceneDetails);
      sceneDetails[0].inputFields.map((te) => {
        for (const [key, value] of Object.entries(te)) {
          console.log(key, value);
          desc = desc.replaceAll("#" + key, value);
        }
      });
      sceneDetails[0].scene_popup.map((te, i) => {
        if (te.icon) {
          desc = desc.replaceAll(
            te.identifier,
            `<span class="tooltip" id='tooltip-${i}'><img src="${te.icon}" alt="PopupIcon" /><span>${te.title}</span></span>`
          );
        } else {
          desc = desc.replaceAll(
            te.identifier,
            `<span class="tooltip" id='tooltip-${i}'><img src ="/assets/PopupIcon.svg" alt="PopupIcon" /><span>${te.title}</span></span>`
          );
        }
        handleTooltip();
      });
      return renderDesc(desc);
    } else if (data.key === "Audio") {
      return (
        // <audio
        //   style={{ width: "100%", marginBottom: "1em" }}
        //   controls
        //   controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
        //   src={data.value}
        // />
        <div className="media-container">
          <div style={{ marginTop: "-1.5em", marginBottom: "-1.5em" }}>
            <Audio content={data.value} />
          </div>
        </div>
      );
    } else if (data.key === "Image") {
      return (
        <div className="media-container">
          <img
            style={{
              width: "100%",
              // maxHeight: "40vh",
              height: "100%",
            }}
            src={data.value}
            alt="SceneFeaturedImage"
          />
        </div>
      );
    } else {
      return (
        <div className="media-container">
          <Video content={data.value} />
        </div>
      );
    }
  }

  React.useEffect(() => {
    LoadScene();
  }, []);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      Object.keys(localStorage).forEach((key) => {
        if (key.includes("PushNotification_")) {
          localStorage.removeItem(key);
        }
      });
      axios
        .get(`${APIURI}/web-pods/allMessages/${podId}`, config)
        .then((res2) => {
          if (
            res2.data.data.length &&
            res2.data.data[0].Pod_notifications.length > 0
          ) {
            console.log(res2.data.data[0].Pod_notifications, "all messages");
            let arry = [];
            res2.data.data[0].Pod_notifications.forEach((resData) => {
              if (resData.displayAt) {
                arry.push(resData);
                localStorage.setItem(
                  `PushNotification_${Math.floor(
                    Date.parse(resData.displayAt) / 1000
                  )}`,
                  resData.description
                );
              }
            });
            console.log(arry);

            // setPodNotificationsOnTime(arry);
          }
        });
    };

    // Set up the interval to call the API every 10 seconds
    const interval = setInterval(() => {
      if (podId) {
        fetchData();
      }
    }, 5000); // 10000 milliseconds = 10 seconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, [podId]);

  useEffect(() => {
    const ShowNotificationOnTime = () => {
      // const currentTimeStamp = Date.now();
      // var myDate = new Date(currentTimeStamp);
      // var pstDate = myDate.toLocaleString("en-US", {
      //   timeZone: "America/Los_Angeles",
      // });
      // console.log(Date.parse(pstDate), "pst date");
      // var now = new Date();
      // var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
      // console.log(
      //   "utcasdf",
      //   Math.floor(Date.parse(new Date().toISOString()) / 1000)
      // );
      let utc_date = Math.floor(Date.parse(new Date().toISOString()) / 1000);

      // var pst_now = new Date(pstDate);
      // console.log("pst to utc ", Date.parse(new Date(pstDate).toISOString()));

      let notificationVar = localStorage.getItem(
        `PushNotification_${utc_date}`
      );
      if (notificationVar) {
        setPushNotificationModal(true);
        setPushNotificationData(notificationVar);
      }
    };

    // Call the function every second (1000 milliseconds)
    const intervalId = setInterval(ShowNotificationOnTime, 1000);

    // To stop calling the function, clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const LoadScene = async () => {
    setLoad(true);
    await axios
      .get(`${APIURI}/web-scene/view/${sceneID}`, config)
      .then((res) => {
        setIsLast(res.data.message.isLast);
        if (res.data.podBody && res.data.podBody.podStatus === "active") {
          if (res.data.podBody && res.data.podBody.podData[0][0]._id) {
            socketNotification.emit(
              "joinRoom",
              `${res.data.podBody.podData[0][0]._id}`
            );
          } else {
            console.error(
              "res.data.podBody or res.data.podBody.podData[0][0]._id is not properly defined."
            );
          }
          // socketNotification.emit(
          //   "joinRoom",
          //   `${res.data.podBody.podData[0][0]._id}`
          // );
          setPodId(res.data.podBody.podData[0][0]._id);
          setPodFrames(res.data.podBody.podData[0][0].Frames);
          setIsPodActive(true);
          console.log(res.data.podBody.podData[0][0]._id);
        }

        let arr = [];
        arr.push(res.data.message);
        setFrames(res.data.message.Frames);
        if (res.data.podBody.podStatus === "active") {
          setShowLeaderBoardBtn(true);
        }
        setSceneDetails(arr);
        setSceneDescription(res.data.message.scene_description);
        console.log(res.data.message.isLast);
        let successMSG = res.data.message.success_message;

        res.data.message.inputFields.map((te) => {
          for (const [key, value] of Object.entries(te)) {
            console.log(key, value);
            successMSG = successMSG.replaceAll("#" + key, value);
          }
        });
        res.data.message.scene_popup.map((te, i) => {
          if (te.icon) {
            successMSG = successMSG.replaceAll(
              te.identifier,
              `<span class="tooltip" id='tooltip-${i}'><img src="${te.icon}" alt="PopupIcon" /><span>${te.title}</span></span>`
            );
          } else {
            successMSG = successMSG.replaceAll(
              te.identifier,
              `<span class="tooltip" id='tooltip-${i}'><img src ="/assets/PopupIcon.svg" alt="PopupIcon" /><span>${te.title}</span></span>`
            );
          }

          // handleTooltip();
        });
        setSuccessMessage(successMSG);
        let arrPop = togglePopoverArr;
        arrPop.push(res.data.message.scene_popup.map((te) => te.description));
        console.log(arrPop);
        setTooglePopoverArr(arrPop);
        setTimeout(() => {
          handleTooltip();
        }, 2000);

        setTimeout(() => {
          UpdateMailToLinks();
        }, 500);
      });
    await axios.get(`${APIURI}/web-lastScreen/view`, config).then((res) => {
      setLoad(true);
      console.log(res.data.message);
      setFacebook(res.data.message[0].facebook);
      setTwitter(res.data.message[0].twitter);
      setInstagram(res.data.message[0].instagram);
      setTiktok(res.data.message[0].tiktok);
      setButtonsElements(res.data.message[0].message);
      setLoad(false);
    });
  };
  if (load) {
    return <Loader />;
  }
  const handleClosePopover = () => {
    setTooglePopover(false);
    // window.location.reload();
  };
  const LastScreen = () => (
    <div className="congratssplash" style={{ paddingBottom: "100px" }}>
      <div className="close" style={{ visibility: "hidden" }}>
        <button onClick={(e) => history.goBack()}>
          <span>[x]</span>Close
        </button>
      </div>
      <div style={{ textAlign: "center", paddingBottom: "0.5em" }}>
        <img
          src="/assets/Lock@3x.png"
          alt=""
          style={{ width: "70px", height: "120px" }}
        />
        {/* {isLast?:<img src="/assets/KeyTransparent.svg" alt="" style={{ width: "100%" }} />} */}
      </div>
      <div className="container-text solution-congrats-black">
        {/* <h2>YOU DID IT! </h2> */}
        {SceneDescription.map((data) => {
          if (data.type === "success_description") {
            return (
              <div key={data._id}>
                {/* <ContentTypeComponent content={data} /> */}
                {ContentTypeComponent(data)}
              </div>
            );
          }
        })}
        {/* <div dangerouslySetInnerHTML={createMarkup(successMessage)} /> */}
        {/* <button onClick={(e) => setTooglePopover(true)}>Popover butoon</button> */}
      </div>
      <div className="container-rate">
        {/* <a className="btn-last" onClick={handleClickOpen}>
          RATE US
        </a> */}
        {buttonsElements.map((btn) => {
          return (
            <a className="btn-last" key={btn._id} href={btn.placeholder}>
              {btn.label}
              {console.log("buttons-element", buttonsElements)}
            </a>
          );
        })}{" "}
        {showLeaderBoardBtn ? (
          <a className="btn-last" href={`/leaderboard:${podId}`}>
            LEADERBOARD
          </a>
        ) : (
          ""
        )}
      </div>
      <Dialog
        open={togglePopover}
        onClose={handleClosePopover}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="PopOver"
      >
        {/* <DialogTitle id="alert-dialog-title">Message</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div dangerouslySetInnerHTML={createMarkup(togglePopoverMessage)} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosePopover}
            className="dialog-btn-dismiss"
            style={{ marginBottom: "2.2rem" }}
            autoFocus
            variant="contained"
          >
            Okay
          </Button>
          {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
        </DialogActions>
      </Dialog>
      <div className="container-social">
        {facebook !== "" ? (
          <a href={`${facebook}`} target="_blank">
            <i class="fa fa-facebook-official" aria-hidden="true"></i>
          </a>
        ) : (
          ""
        )}
        {twitter !== "" ? (
          <a href={`${twitter}`} target="_blank">
            <i class="fa fa-twitter-square" aria-hidden="true"></i>
          </a>
        ) : (
          ""
        )}
        {instagram !== "" ? (
          <a href={`${instagram}`} target="_blank">
            <i class="fa fa-instagram" aria-hidden="true"></i>
          </a>
        ) : (
          ""
        )}
        {tiktok !== "" ? (
          <a href={`${tiktok}`} target="_blank">
            <i class="fa fa-music" aria-hidden="true"></i>
          </a>
        ) : (
          ""
        )}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        className="feedbackDialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Feedback"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            How was your experience playing Escapely?
          </DialogContentText>
          {/* <StarRatings
                    rating={rating}
                    starRatedColor="blue"
                    changeRating={changeRating}
                    numberOfStars={6}
                    name='rating'
                    /> */}
          <ReactStars className="star-rating" {...StarRatings} /> <br />
          <TextField
            id="outlined-basic"
            label="Feedback"
            type="textarea"
            variant="outlined"
            fullWidth="true"
            onChange={(e) => setReview(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            className="cancelButton"
            onClick={handleClose}
            color="warning"
            variant="contained"
          >
            <CancelIcon />
          </Button>
          <Button
            onClick={submitFeedback}
            color="primary"
            autoFocus
            variant="contained"
            className="submitFeedback"
          >
            Submit Feedback
          </Button>
        </DialogActions>
      </Dialog>

      {/* push nofication  */}
      <Dialog
        open={pushNotificationModal}
        onClose={() => {
          setPushNotificationModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="PopOver"
      >
        {/* <DialogTitle id="alert-dialog-title">Message</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <div dangerouslySetInnerHTML={createMarkup(pushNotificationData)} /> */}
            <div>
              <p>{pushNotificationData}</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPushNotificationModal(false);
            }}
            style={{ marginBottom: "2.2rem" }}
            className="dialog-btn-dismiss"
            autoFocus
            variant="contained"
          >
            Okay
          </Button>
          {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
        </DialogActions>
      </Dialog>
      <Footer
        isLoginRequired={sceneDetails.length && sceneDetails[0].login_required}
        tutorialLink={`/tutorial:${
          sceneDetails.length && sceneDetails[0].game_id
        }`}
      />
    </div>
  );
  const IsNotLast = () => (
    <div className="congratssplash-white" style={{ paddingBottom: "100px" }}>
      <div className="close" style={{ visibility: "hidden" }}>
        <button onClick={(e) => history.goBack()}>
          <span>[x]</span>Close
        </button>
      </div>
      <div className="container-img">
        <img
          src="/assets/KeyTransparent.svg"
          alt=""
          style={{ width: "100%" }}
        />
      </div>
      <div className="container-text solution-congrats">
        {/* <h2>YOU DID IT! </h2> */}
        {/* <div dangerouslySetInnerHTML={createMarkup(successMessage)} /> */}
        {SceneDescription.map((data) => {
          if (data.type === "success_description") {
            return (
              <div key={data._id}>
                {/* <ContentTypeComponent content={data} /> */}
                {ContentTypeComponent(data)}
              </div>
            );
          }
        })}
      </div>
      <div className="container-social-white">
        {facebook !== "" ? (
          <a href={`${facebook}`} target="_blank">
            <i class="fa fa-facebook-official" aria-hidden="true"></i>
          </a>
        ) : (
          ""
        )}
        {twitter !== "" ? (
          <a href={`${twitter}`} target="_blank">
            <i class="fa fa-twitter-square" aria-hidden="true"></i>
          </a>
        ) : (
          ""
        )}
        {instagram !== "" ? (
          <a href={`${instagram}`} target="_blank">
            <i class="fa fa-instagram" aria-hidden="true"></i>
          </a>
        ) : (
          ""
        )}
        {tiktok !== "" ? (
          <a href={`${tiktok}`} target="_blank">
            <i class="fa fa-music" aria-hidden="true"></i>
          </a>
        ) : (
          ""
        )}
      </div>
      <Dialog
        open={togglePopover}
        onClose={handleClosePopover}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="PopOver"
      >
        {/* <DialogTitle id="alert-dialog-title">Message</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div dangerouslySetInnerHTML={createMarkup(togglePopoverMessage)} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosePopover}
            className="dialog-btn-dismiss"
            style={{ marginBottom: "2.2rem" }}
            autoFocus
            variant="contained"
          >
            Okay
          </Button>
          {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
        </DialogActions>
      </Dialog>

      {/* push nofication  */}
      <Dialog
        open={pushNotificationModal}
        onClose={() => {
          setPushNotificationModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="PopOver"
      >
        {/* <DialogTitle id="alert-dialog-title">Message</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <div dangerouslySetInnerHTML={createMarkup(pushNotificationData)} /> */}
            <div>
              <p>{pushNotificationData}</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPushNotificationModal(false);
            }}
            style={{ marginBottom: "2.2rem" }}
            className="dialog-btn-dismiss"
            autoFocus
            variant="contained"
          >
            Okay
          </Button>
          {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
        </DialogActions>
      </Dialog>
      <Footer
        isLoginRequired={sceneDetails.length && sceneDetails[0].login_required}
        tutorialLink={`/tutorial:${
          sceneDetails.length && sceneDetails[0].game_id
        }`}
      />
    </div>
  );
  if (isLast) {
    return LastScreen();
    // return <LastScreen />;
  } else {
    return IsNotLast();
    // return <IsNotLast />;
  }
}
export default withRouter(CongratsStory);
