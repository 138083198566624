import React from "react";

export default function Play(props) {
  const { handleClick } = props;

  return (
    <button className="player__button" onClickCapture={() => handleClick()}>
        <svg xmlns="http://www.w3.org/2000/svg"  width="44" height="44" viewBox="0 0 44 44">
        <defs>
            <filter id="Ellipse_2" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="3" result="blur"/>
            <feFlood flood-opacity="0.161"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
            </filter>
        </defs>
        <g id="Play" transform="translate(-67 -213)">
            <g transform="matrix(1, 0, 0, 1, 67, 213)" filter="url(#Ellipse_2)">
            <circle id="Ellipse_2-2" data-name="Ellipse 2" cx="13" cy="13" r="13" transform="translate(9 6)"/>
            </g>
            <path id="Path_159" data-name="Path 159" d="M-279.379,233.88l8.1,4.522-8.1,4.522Z" transform="translate(365.746 -6.402)" fill="#fffb00"/>
        </g>
        </svg>
    </button>
  );
}
