import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import { APIURI } from "../config";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { TermsPopUp } from "../popups/TermsPopUp";
import TermsChecked from "../context/TermsChecked";
import IsTermsPopUp from "../context/IsTermsPopUp";

function AccountSetup(props) {
  let history = useHistory();
  const gameId = props.match.params.gameID;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [fullname, setFullname] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useContext(TermsChecked);
  const [isPopupOpen, setIsPopupOpen] = useContext(IsTermsPopUp);
  let currentUrl = window.location.href.split(":").pop();
  console.log(currentUrl);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const handleSave = (event) => {
    event.preventDefault();
    setLoading(true);
    const data = {
      email: email.toLowerCase(),
      password: password,
      confirmPassword: cpassword,
      name: fullname,
    };
    if (isTermsChecked) {
      axios
        .post(`${APIURI}/users/signup`, data)
        .then((res) => {
          //   Toast.fire({
          //     icon: "success",
          //     text: "Registration Successfull!",
          //   });

          if (res.data.error) {
            if (
              res.data.message === '"confirmPassword" must be [ref:password]'
            ) {
              setErrorMessage("Passwords do NOT match!");
              setLoading(false);
            } else if (
              res.data.message ===
              '"password" length must be at least 4 characters long'
            ) {
              setErrorMessage(
                "Password length must be at least four characters long."
              );
              setLoading(false);
            } else {
              setErrorMessage(res.data.message);
              setLoading(false);
            }
          } else {
            // if (gameId === undefined) {
            //   window.location.href = "/auth";
            // } else {
            //   window.location.href = `/auth:${gameId}`;
            // }
            // console.log(res)
            const data = {
              email: email.toLowerCase(),
              password: password,
            };
            axios
              .post(`${APIURI}/users/login`, data)
              .then((loginRes) => {
                const token = loginRes.data.accessToken;
                console.log(token);
                localStorage.setItem("accesstoken", token);
                const prevURL = localStorage.getItem("redirect_url");
                if (window.location.href.includes("pod:")) {
                  window.location.href = `/pods:${
                    window.location.href.split("pod:").pop().split("/")[0]
                  }`;
                } else if (gameId === undefined) {
                  if (prevURL) {
                    window.location.href = prevURL;
                  } else {
                    // setErrorMessage(
                    //   "Scan QR code before logging in to begin play."
                    // );

                    window.location.href = "/profile";
                  }
                  // if (prevURL) {
                  //   window.location.href = prevURL;
                  // } else {
                  //   window.location.href = "/noqr";
                  // }
                  // history.push(prevURL);
                  setLoading(false);
                } else {
                  if (prevURL) {
                    window.location.href = prevURL;
                  } else if (currentUrl.split("/").pop() === "0") {
                    // setErrorMessage("Scan QR code before logging in to begin play.");
                    window.location.href = `/location:${
                      currentUrl.split("/")[0]
                    }`;
                  } else if (currentUrl) {
                    // setErrorMessage("Scan QR code before logging in to begin play.");
                    window.location.href = `/solution:${currentUrl}`;
                  } else {
                    // setErrorMessage(
                    //   "Scan QR code before logging in to begin play."
                    // );

                    window.location.href = "/profile";
                  }
                  // history.push(prevURL);
                  // if (prevURL) {
                  //   window.location.href = prevURL;
                  // } else {
                  //   window.location.href = "/noqr";
                  // }
                  // history.push(prevURL);
                  setLoading(false);
                }
              })
              .catch(function (loginerror) {
                if (loginerror.response) {
                  setErrorMessage(loginerror.response.data.message);
                  setLoading(false);
                }
              });
          }
          console.log(res.data);
        })
        .catch(function (error) {
          console.log(error.response.data);
          if (error.response.data.message === "Customer created successfully") {
            const data = {
              email: email.toLowerCase(),
              password: password,
            };
            axios
              .post(`${APIURI}/users/login`, data)
              .then((loginRes) => {
                const token = loginRes.data.accessToken;
                console.log(token);
                localStorage.setItem("accesstoken", token);
                const prevURL = localStorage.getItem("redirect_url");
                if (window.location.href.includes("pod:")) {
                  window.location.href = `/pods:${
                    window.location.href.split("pod:").pop().split("/")[0]
                  }`;
                } else if (gameId === undefined) {
                  if (prevURL) {
                    window.location.href = prevURL;
                  } else {
                    // setErrorMessage(
                    //   "Scan QR code before logging in to begin play."
                    // );

                    window.location.href = "/profile";
                  }
                  // if (prevURL) {
                  //   window.location.href = prevURL;
                  // } else {
                  //   window.location.href = "/noqr";
                  // }
                  // history.push(prevURL);
                  setLoading(false);
                } else {
                  if (prevURL) {
                    window.location.href = prevURL;
                  } else if (currentUrl.split("/").pop() === "0") {
                    // setErrorMessage("Scan QR code before logging in to begin play.");
                    window.location.href = `/location:${
                      currentUrl.split("/")[0]
                    }`;
                  } else if (currentUrl) {
                    // setErrorMessage("Scan QR code before logging in to begin play.");
                    window.location.href = `/solution:${currentUrl}`;
                  } else {
                    // setErrorMessage(
                    //   "Scan QR code before logging in to begin play."
                    // );
                    window.location.href = "/profile";
                  }
                  // history.push(prevURL);
                  // if (prevURL) {
                  //   window.location.href = prevURL;
                  // } else {
                  //   window.location.href = "/noqr";
                  // }
                  // history.push(prevURL);
                  setLoading(false);
                }
              })
              .catch(function (loginerror) {
                if (loginerror.response) {
                  setErrorMessage(loginerror.response.data.message);
                  setLoading(false);
                }
              });
          } else {
            setErrorMessage(error.response.data.message);
            setLoading(false);
          }
        });
    } else {
      setErrorMessage("Please accept Terms & Conditions.");
      // setIsPopupOpen(true)
      setLoading(false);
    }
  };
  return (
    <div className="Auth-login">
      <div className="Auth-header">
        <button className="backbutton-signup" onClick={(e) => history.goBack()}>
          <ArrowBackIcon />
        </button>
        <div className="container">
          <img
            src="/assets/Escapely@3x.png"
            alt="Logo"
            style={{ width: "100%" }}
          />
        </div>

        <p>Sign up to continue</p>
      </div>
      <div className="Auth-container">
        {/* <TextField
          type="text"
          id="outlined-basic"
          label="FULLNAME"
          variant="standard"
          required="true"
          onChange={(e) => setFullname(e.target.value)}
        />{" "} */}
        <input
          type="text"
          className="login-input"
          placeholder="ENTER FULL NAME"
          autocomplete="off"
          onChange={(e) => {
            setFullname(e.target.value);
            setErrorMessage("");
          }}
        />
        <label className="mb-15">FULL NAME</label>
        <br />
        {/* <TextField
          type="email"
          id="outlined-basic"
          label="EMAIL"
          variant="standard"
          required="true"
          onChange={(e) => setEmail(e.target.value)}
        />{" "} */}
        <input
          className="login-input login-email"
          placeholder="ENTER EMAIL"
          autocomplete="off"
          onChange={(e) => {
            setEmail(e.target.value);
            setErrorMessage("");
          }}
        />
        <label className="mb-15">EMAIL</label>
        <br />
        {/* <TextField
          type="password"
          id="outlined-basic"
          label="PASSWORD"
          variant="standard"
          required="true"
          onChange={(e) => setPassword(e.target.value)}
        />{" "} */}
        <div className="password-txt">
          <input
            type="password"
            placeholder="ENTER PASSWORD"
            className="login-input password-circle"
            autocomplete="off"
            onChange={(e) => {
              setPassword(e.target.value);
              setErrorMessage("");
            }}
          />
          <label className="mb-15">PASSWORD</label>
          <br />
          {/* <TextField
          type="password"
          id="outlined-basic"
          label="CONFIRM PASSWORD"
          variant="standard"
          required="true"
          onChange={(e) => setCPassword(e.target.value)}
        />{" "} */}
          <input
            type="password"
            className="login-input password-circle"
            placeholder="CONFIRM PASSWORD"
            autocomplete="off"
            onChange={(e) => {
              setCPassword(e.target.value);
              setErrorMessage("");
            }}
          />
          <label>CONFIRM PASSWORD</label>
        </div>
        <br />
        <p className="wrong-ans">{errorMessage}</p>
        {isPopupOpen ? <TermsPopUp type="signup" /> : ""}
        <div style={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel
            style={{ marginRight: "5px" }}
            className="terms-condition-label"
            control={
              <Checkbox
                className="terms-checkbox"
                // onChange={(e) => {
                //   setIsTermsChecked(e.target.checked);
                //   console.log(e.target.checked);
                // }}
                onClick={() => {
                  setIsPopupOpen(true);
                }}
                name="checkedB"
                checked={isTermsChecked}
                color="dark"
              />
            }
            label=<p className="terms-condition">I accept the</p>
          />
          <span
            className="terms-condition"
            onClick={() => {
              setIsPopupOpen(true);
            }}
            style={{ color: "#000", textDecoration: "underline" }}
          >
            Terms & Conditions
          </span>
        </div>
        {/* <FormControlLabel control={ <Checkbox checked={true} name="checkedB" color="primary" />} label="I agree to the terms and conditions"/> <br /> */}
        {loading ? (
          <Button
            className="signup"
            style={{ background: "#333200" }}
            variant="contained"
            disabled={true}
          >
            SIGN UP
          </Button>
        ) : (
          <Button className="signup" variant="contained" onClick={handleSave}>
            SIGN UP
          </Button>
        )}
      </div>
      {/* <div className="Auth-footer">
        {gameId === undefined ? (
          <span>
            Already a Member?{" "}
            <a className="cra" href="/auth">
              login
            </a>
          </span>
        ) : (
          <span>
            Already a Member?{" "}
            <a className="cra" href={`/auth:${gameId}`}>
              login
            </a>
          </span>
        )}
      </div>{" "} */}
      <br />
      <br /> <br />
      <br />
      <br /> <br /> <br />
    </div>
  );
}
export default withRouter(AccountSetup);
