import React from "react";

const DrawerSplashForExpirePods = () => {
  return (
    <div className="drawersplash">
      <div className="container-img">
        <img
          src="/assets/Lock@3x.png"
          alt=""
          style={{ width: "70px", height: "120px" }}
        />
      </div>
      <div className="container-text">
        <div className="confirmation-screen">
          <h2 style={{ textAlign: "left" }}>EVENT OVER</h2>
          <p>
            This event has ended. If you believe you are seeing this in error,
            please contact{" "}
            <a href="mailto:support@escapely.com" className="expire--pod--mail">
              support@escapely.com
            </a>{" "}
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default DrawerSplashForExpirePods;
