import React, { useState, useEffect } from "react";

const CountdownTimer = ({ targetDate, handleIsTimerEnd }) => {
  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const targetTime = new Date(targetDate).getTime();
    const timeDifference = targetTime - now;

    if (timeDifference <= 0) {
      // Target date has already passed
      handleIsTimerEnd();
      return {
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
      };
    }

    // Calculate time left in days, hours, minutes, and seconds
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
      .toString()
      .padStart(2, "0");
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    )
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000)
      .toString()
      .padStart(2, "0");

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear the timer when the component is unmounted
    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <>
      {timeLeft.days}:{timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
      {/* {timeLeft.days} days, {timeLeft.hours} hours, {timeLeft.minutes} minutes,{" "}
      {timeLeft.seconds} seconds */}
    </>
  );
};

export default CountdownTimer;
