import React from "react";
import { useHistory } from "react-router";

export default function SplashScreen() {
  let history = useHistory();
  window.location.href="https://escapely.com/"
  return (
    <div className="Splash" onClick={(e) => history.goBack()}>
      {/* <div className="Splash-header">
        <img
          src="/assets/Escapely_big@3x.png"
          alt="Logo"
          className="Splash-logo"
        />
      </div> */}
    </div>
  );
}
