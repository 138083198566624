import React, { useEffect } from "react";
import "./legacy.css";
import Authentication from "./stories/Authentication";
import Hints from "./stories/Hints";
import LocationStory from "./stories/LocationStory";
import SplashScreen from "./stories/Splash";

import { Switch, Route, Redirect } from "react-router-dom";
import DrawerSplash from "./stories/DrawerSplash";
import Solutions from "./stories/Solutions";
// import Tutorial from './stories/Tutorial';
import TutorialStory from "./stories/TutorialStory";
// import Page404 from './Page404';
import AccountSetup from "./stories/AccountSetup";
import ForgotPassword from "./stories/ForgotPassword";
import CongratsStory from "./stories/CongratsStory";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import smoothscroll from "smoothscroll-polyfill";
import Store from "./store/Store";
import TermsChecked from "./context/TermsChecked";
import { useState } from "react";
import IsTermsPopUp from "./context/IsTermsPopUp";
import PodStory from "./stories/PodStory";
import LeaderBoard from "./stories/LeaderBoard";
import DrawerSplashForPods from "./stories/DrawerSplashForPods";
import DrawerSplashForExpirePods from "./stories/DrawerSplashForExpirePods";
import ProfileScreen from "./stories/ProfileScreen";
import EditProfileScreen from "./stories/EditProfileScreen";
import ForceForgotPassword from "./stories/ForceForgotPassword";

function App() {
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const NoQRComponent = () => {
    return (
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Did you skip to scan the QR Code?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* Oops! looks like you’ve skipped the previous Game QR Code, Please
            scan the Game QR Code to continue.! */}
            Oops! looks like you’ve skipped to scan the QR Code, Please scan the
            QR Code to continue!!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="dark" autoFocus variant='contained'>
            Go Back
          </Button> */}
          {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
        </DialogActions>
      </Dialog>
    );
  };
  const NoSceneQRComponent = () => {
    return (
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Did you skip the Scene QR Code?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Oops! looks like you’ve skipped the previous QR Code, Please scan
            the Scene QR Code to continue.!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="dark" autoFocus variant='contained'>
            Go Back
          </Button> */}
        </DialogActions>
      </Dialog>
    );
  };
  return (
    <>
      <TermsChecked.Provider value={[isTermsChecked, setIsTermsChecked]}>
        <IsTermsPopUp.Provider value={[isPopupOpen, setIsPopupOpen]}>
          <Switch>
            <Route exact path="/">
              <SplashScreen />
            </Route>
            <Route exact path="/auth">
              <Authentication />
            </Route>
            <Route exact path="/signup">
              <AccountSetup />
            </Route>
            <Route exact path="/forgotpassword">
              <ForgotPassword />
            </Route>
            <Route exact path="/forgotpassword::gameID/:sceneId">
              <ForgotPassword />
            </Route>
            <Route exact path="/forceforgotpassword">
              <ForceForgotPassword />
            </Route>
            <Route exact path="/forceforgotpassword::gameID/:sceneId">
              <ForceForgotPassword />
            </Route>
            <Route exact path="/auth::gameID/:sceneId">
              <Authentication />
            </Route>
            <Route exact path="/signup::gameID/:sceneId">
              <AccountSetup />
            </Route>
            {/* <Route exact path='/location' component={NoQRComponent} />
      <Route exact path='/solution' component={NoSceneQRComponent} /> */}
            <Route exact path="/location::gameID">
              <LocationStory />
            </Route>
            <Route exact path="/pods::gameID">
              <PodStory />
            </Route>
            <Route exact path="/hints">
              <Hints />
            </Route>
            <Route exact path="/profile">
              <ProfileScreen />
            </Route>
            <Route exact path="/editprofile">
              <EditProfileScreen />
            </Route>
            <Route exact path="/leaderboard::podId">
              <LeaderBoard />
            </Route>
            <Route exact path="/hints::sceneID">
              <Hints />
            </Route>
            <Route exact path="/tutorial::gameID">
              <TutorialStory />
            </Route>
            <Route exact path="/drawer">
              <DrawerSplash />
            </Route>
            <Route exact path="/drawerpods">
              <DrawerSplashForPods />
            </Route>
            <Route exact path="/podExpire">
              <DrawerSplashForExpirePods />
            </Route>
            <Route exact path="/congrats::sceneID">
              <CongratsStory />
            </Route>
            <Route exact path="/solution::gameID/:sceneID">
              <Solutions />
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
            {(window.isAudioPlaying = false)}
            {(window.audioState = [])}
          </Switch>
        </IsTermsPopUp.Provider>
      </TermsChecked.Provider>
    </>
  );
}

export default App;
